import React from "react";
import styles from "./ShowMore.module.scss";

const ShowMore = ({ pageOptions, setPageOptions }) => {
  return (
    <div className="pagination btn-container">
      <button
        className="btn btn-sm btn-primary me-2"
        onClick={() =>
          setPageOptions({
            ...pageOptions,
            page: Math.max(pageOptions.page - 1, 0),
          })
        }
        disabled={pageOptions.page === 1}
      >
        Prev
      </button>

      <button
        className="btn btn-sm btn-primary"
        onClick={() => {
          setPageOptions({
            ...pageOptions,
            page: pageOptions.page + 1,
          });
        }}
        disabled={!pageOptions.hasNextPage}
      >
        Next
      </button>
      {/* <div>{isFetching ? "Fetching..." : null}</div> */}
    </div>
  );
};

export default ShowMore;
