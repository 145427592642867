import axios from "axios";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import Loader from "../../components/global/Loader";
import { success } from 'tata-js';

import ShowMore from "../../components/global/ShowMore";
import SingleLineQuestionView from "../../components/notebook/SingleLineQuestionView";
import Searchbar from "../../components/notebook/Searchbar";
import QuestionView from "../../components/notebook/QuestionView";

const API_URL = process.env.REACT_APP_API_URL;

const GET_NOTEBOOKS = `${API_URL}/api/v1/notebooks`;
const fetchNotebooks = async (pagination, notebooks, setNotebooks) => {
  const response = await axios.get(
    `${GET_NOTEBOOKS}?page=${pagination.page}&limit=${pagination.limit}&isArchived=true`
  );
  setNotebooks(response?.data?.data?.notebooks);
  return response.data;
};

const ArchivedNoteBooks = () => {
  const [notebooks, setNotebooks] = useState([]);
  const [selectedNotebook, setSelectedNotebook] = useState(null);
  const [notebookShowMore, setNotebookShowMore] = useState({
    hasNextPage: false,
    page: 1,
    limit: 10,
  });
  const [questionShowMore, setQuestionShowMore] = useState({
    hasNextPage: false,
    page: 1,
    limit: 10,
  });
  const [searchValue, setSearchValue] = useState("");
  const [showQuestion, setShowQuestion] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const isNotebooksFiltered = searchValue.trim() !== "";

  const {
    data: notebookData,
    refetch,
    isLoading,
    isFetching,
  } = useQuery(
    [GET_NOTEBOOKS, notebookShowMore.page, notebookShowMore.limit],
    () => fetchNotebooks(notebookShowMore, notebooks, setNotebooks),
    { keepPreviousData: true, enabled: !isNotebooksFiltered }
  );

  const fetchNotebook = async () => {
    if (!selectedNotebook) return {};
    const response = await axios.get(
      `${GET_NOTEBOOKS}/${selectedNotebook?._id}?page=${questionShowMore.page}&limit=${questionShowMore.limit}`
    );
    return response.data;
  };

  const {
    data: selectedNotebookData,
    isLoading: selectedNotebookLoading,
    refetch: refetchNotebook,
    isFetching: isFetchingQuestion,
  } = useQuery(
    [
      `${GET_NOTEBOOKS}/${selectedNotebook?._id}`,
      questionShowMore.page,
      questionShowMore.limit,
    ],
    fetchNotebook,
    { keepPreviousData: true }
  );

  useEffect(() => {
    setNotebookShowMore({
      ...notebookShowMore,
      count: notebookData?.count,
      hasNextPage: notebookData?.hasNextPage,
    });
  }, [notebookData]);

  useEffect(() => {
    if (selectedNotebookData) {
      setQuestionShowMore({
        ...questionShowMore,
        count: selectedNotebookData?.count,
        hasNextPage: selectedNotebookData?.hasNextPage,
      });
    }
  }, [selectedNotebookData]);

  const unarchiveNotebook = async (notebook) => {
    if (!notebook) return;
    const notebookId = notebook._id;
    await axios.patch(`${GET_NOTEBOOKS}/${notebookId}/unarchive`);
    refetch();
    setSelectedNotebook(null);
    success('Notebook Unarchived', 'Unarchived your notebook Successfully!', {
      position: 'tr',
      duration: 5000,
    });
  };

  const viewQuestion = (question, questionIndex) => {
    setSelectedQuestion(question);
    setShowQuestion(true);
    setCurrentIndex(questionIndex);
  };
  const hideViewQuestion = () => {
    setShowQuestion(false);
  };

  return (
    <>
      {/* {JSON.stringify(notebooks, null, 2)} */}
      <Searchbar
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setNotebooks={setNotebooks}
      />
      <div className="row">
        <div className="col-12 col-md-3 right-border1">
          <div className="card">
            <div className="card-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h4 className="mb-0">Archived Notebooks</h4>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="list-group h-20">
                {isLoading && <Loader height="20vh" />}
                {!isLoading && notebooks?.length === 0 ? (
                  <div className="d-flex align-items-center h-20 justify-content-center">
                    <p className="text-muted mb-0">No Notebooks Found</p>
                  </div>
                ) : (
                  <>
                    {notebooks?.map((notebook, index) => (
                      <button
                        key={index}
                        className={`list-group-item list-group-item-action z1 ${selectedNotebook &&
                        selectedNotebook?._id === notebook?._id
                          ? `text-primary`
                          : ""
                        }`}
                        onClick={() => setSelectedNotebook(notebook)}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <h5 className="mb-0">
                              {notebook?.title + " "}
                            </h5>
                          </div>

                          <div className="d-flex">
                            <div className="ms-3" onClick={unarchiveNotebook.bind(this, notebook)}>
                              <span className={`fe fe-archive`}></span>
                              <p className="d-inline ms-1">Unarchive</p>
                            </div>
                          </div>
                        </div>
                      </button>
                    ))}

                    {isFetching && <Loader height="20vh" />}

                    {
                      !isNotebooksFiltered &&
                      <ShowMore
                        pageOptions={notebookShowMore}
                        setPageOptions={setNotebookShowMore}
                      />
                    }
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-9">
          {selectedNotebook ? (
            <>
              <div className="card">
                <h3 className="card-header">
                  {selectedNotebook?.title}
                </h3>
                <div className="card-body">
                  <ol className="list-group list-group-numbered">
                    {selectedNotebookLoading && <Loader height="20vh" />}
                    {!selectedNotebookLoading &&
                    selectedNotebookData?.data?.length === 0 ? (
                      <>
                        <div className="d-flex justify-content-center align-items-center h-20">
                          <h5 className="mb-0 text-muted">
                            You have no questions in this notebook.
                          </h5>
                        </div>
                      </>
                    ) : (
                      <>
                        {selectedNotebookData?.data?.map((question, index) => (
                          <li
                            className="list-group-item d-flex justify-content-between align-items-start cursor-pointer p-0 border-none"
                          >
                            <SingleLineQuestionView
                              questionIndex={index}
                              question={question}
                              selectedNotebook={selectedNotebook}
                              refresh={() => {
                                refetch();
                                refetchNotebook();
                              }}
                              onViewQuestion={viewQuestion}
                            />
                          </li>
                        ))}

                        {isFetchingQuestion && <Loader height="20vh" />}

                        <ShowMore
                          pageOptions={questionShowMore}
                          setPageOptions={setQuestionShowMore}
                        />
                      </>
                    )}
                  </ol>
                </div>
              </div>
            </>
          ) : ('')}
        </div>
      </div>
      <QuestionView
        show={showQuestion}
        hide={hideViewQuestion}
        question={selectedQuestion}
        notebookId={selectedNotebook?._id}
        refresh={() => {
          refetch();
          refetchNotebook();
        }}
        disableNext={currentIndex === selectedNotebookData?.data?.length - 1}
        disablePrev={currentIndex === 0}
        onNext={() => {
          viewQuestion(selectedNotebookData?.data[currentIndex + 1], currentIndex + 1);
        }}
        onPrev={() => {
          viewQuestion(selectedNotebookData?.data[currentIndex - 1], currentIndex - 1);
        }}
      />
    </>
  );
};

export default ArchivedNoteBooks;
