import { createSlice } from "@reduxjs/toolkit";

export const resoSlice = createSlice({
  name: "reso",
  initialState: {
    resoSubject: null,
    resoChapter: null,
    chapterFilters: {
      limit: 10,
      page: 0,
      filter: "all",
      pack: null,
      importance: "all",
      group: null,
    },
  },
  reducers: {
    setSubject: (state, action) => {
      localStorage.setItem("resoSubject", JSON.stringify(action.payload));
      state.resoSubject = action.payload;
    },
    setChapter: (state, action) => {
      localStorage.setItem("resoChapter", JSON.stringify(action.payload));
      state.resoChapter = action.payload;
    },
    updateFilters: (state, action) => {
      state.chapterFilters = {
        ...state.chapterFilters,
        ...action.payload,
      };
    },
    resetFilters: (state) => {
      state.chapterFilters = {
        limit: 10,
        page: 0,
        pageCount: 0,
        filter: "all",
        pack: null,
        importance: "all",
        group: null,
      };
    },
  },
});

export const { setSubject, setChapter, updateFilters, resetFilters } =
  resoSlice.actions;

export default resoSlice.reducer;
