import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Meta from "../../components/global/Meta";
import Dashboard from "../../layout/dashboard";
import NoteBooks from "./notebooks.jsx";
import ArchivedNoteBooks from "./archivedNotebooks";
import Tags from "./tags";

const NoteBook = ({ match, location, children }) => {

  const router = useHistory();
  const archivedNotebooksPathName = "/v2/notebooks/archived";
  const tagsNotebooksPathName = "/v2/notebooks/tags";

  const [pathName, setPathName] = useState();

  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);

  const getComponent = () => {

    if (pathName === tagsNotebooksPathName) {
      return <Tags />;
    } else if(pathName === archivedNotebooksPathName) {
      return <ArchivedNoteBooks />;
    } else {
      return <NoteBooks />;
    }
  };

  // const goToArchivedNotebooks = () => {
  //   console.log("clicked")
  //   router.push(`/v2/notebooks/archived`);
  // };

  // const goToNotebooks = () => {
  //   console.log("clicked notebook")
  //   router.push(`/v2/notebooks`);
  // };

  return (
    <Dashboard>
      <Meta title={`Notebooks`} />
      <div className="header">
        <div className="container-fluid pt-4">
          <div className="row align-items-end">
            <div className="col">
              <h6 className="header-pretitle text-secondary">Overview</h6>
              <h1 className="header-title">Notebooks</h1>
            </div>
            {/* <div className="col-auto">
              {
                pathName === archivedNotebooksPathName ?
                  <button className="btn btn-primary" onClick={goToNotebooks}>
                    <p className="d-inline ms-1">Notebooks</p>
                  </button>
                  : <button className="btn btn-primary" onClick={goToArchivedNotebooks}>
                    <span className={`fe fe-archive`}></span>
                    <p className="d-inline ms-1">Archived Notebooks</p>
                  </button>
              }
            </div> */}
          </div>
        </div>
      </div>
      <div className="container-fluid">{getComponent()}</div>
    </Dashboard>
  );
};

export default NoteBook;
