import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useCookies } from "react-cookie";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const NotesModal = ({ show, handleClose, notes, questionId, handleNoteExists }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [note, setNote] = useState("");
  const [cookies] = useCookies();
  const [loading, setLoading] = useState(false);

  const fetchNotes = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/api/v2/notes/${questionId}`, {
        headers: {
          Authorization: `Bearer ${cookies.token}`,
        },
      })
      .then((res) => {
        setNote(res.data?.data);
        setIsEditMode(!res.data?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!questionId) return;
    if (!cookies.token) return;
    fetchNotes();
  }, [questionId, cookies.token]);

  const saveNote = () => {
    setLoading(true);
    updateNotes(note);
  };

  const updateNotes = (noteText) => {
    axios
      .patch(
        `${API_URL}/api/v2/notes/${questionId}`,
        { notes: noteText },
        { headers: { Authorization: `Bearer ${cookies.token}` } }
      )
      .then((res) => {
        setIsEditMode(false);
        if (!noteText) {
          fetchNotes();
          handleNoteExists(false);
        } else {
          handleNoteExists(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editNote = () => {
    setIsEditMode(true);
  };

  useEffect(() => {
    setNote(notes);
    setIsEditMode(!notes);
  }, [notes]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <div
          className="spinner-border spinner-border-sm text-primary"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <div className="modal-content">
        <div className="modal-card card">
          <div className="card-header">
            <h4 className="card-header-title">Let's take notes for this question</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            ></button>
          </div>
          <div className="card-body">
            {isEditMode ? (
              <div className="">
                <div className="form-group mb-3">
                  <textarea
                    onChange={(e) => setNote(e.target.value)}
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    placeholder="Your notes..."
                    value={note}
                  ></textarea>
                </div>
                <div className="d-flex justify-content-end gap-2">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={saveNote}
                    disabled={!note}
                  >
                    <span className="fe fe-save cursor-pointer pe-2"></span>
                    Save
                  </button>
                </div>
              </div>
            ) : (
              <div className="">
                <p className="mb-3">{note}</p>
                <div className="d-flex justify-content-end gap-2">
                  <button
                    className="btn btn-danger btn-sm rounded px-3"
                    onClick={() => {
                      updateNotes("");
                    }}
                  >
                    <span className="fe fe-trash cursor-pointer pe-2"></span>
                    Delete
                  </button>
                  <button
                    className="btn btn-primary btn-sm btn-dark rounded px-3"
                    onClick={editNote}
                  >
                    <span className="fe fe-edit cursor-pointer pe-2"></span>
                    Edit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NotesModal;
