class ApiQueue {
  constructor() {
    this.eventsQ = [];
    this.isProcessing = false;
  }

  enQueue(event) {
    this.eventsQ.push(event);
    if (!this.isProcessing) {
      // process the Q in the next tick
      this.processQueue();
    }
  }

  async processQueue() {
    this.isProcessing = true;

    while (this.eventsQ.length > 0) {
      if (!navigator.onLine) {
        // No internet connect so wait for 5 seconds
        await new Promise((resolve) => setTimeout(resolve, 5000));
        continue;
      }
      const event = this.eventsQ[0];

      try {
        const response = await event();
        if (response) {
          // remove the event from the Q
          this.eventsQ.shift();
        }
      } catch (e) {
        console.error(`Error processing api ${event.id}: ${e.message}`);
      }
    }

    this.isProcessing = false;
  }
}

export default ApiQueue;
