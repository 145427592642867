import axios from "axios";
import React, { useState, useEffect } from "react";
import TagForm from "../../components/notebook/TagForm";
import { useQuery } from "react-query";
import { error, success } from 'tata-js';
import Loader from "../../components/global/Loader";

const GET_TAGS = `/api/v1/tags/getTag`;
const fetchTags = async () => {
  const response = await axios.get(GET_TAGS);
  return response.data;
};

const Tags = () => {
  const [tags, setTags] = useState(["Hard", "Easy", "Medium"]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [showTagForm, setShowTagForm] = useState(false);
  const [tagMode, setTagMode] = useState("add");

  const { data: tagsData, refetch, isLoading } = useQuery(GET_TAGS, fetchTags);

  const addTag = () => {
    setTagMode("add");
    setShowTagForm(true);
  };
  const editTag = (tag) => {
    setSelectedTag(tag);
    setTagMode("edit");
    setShowTagForm(true);
  };
  const hideTag = () => {
    setShowTagForm(false);
  };

  const deleteTag = async (tag) => {
    try {
      await axios.delete(`/api/v1/tags/${tag}`);
      refetch();
      success('Tag Deleted', "Tag deleted successfully", {
        position: 'tr',
        duration: 5000,
      });
    } catch (e) {
      error('Error', "Error deleting tag", {
        position: 'tr',
        duration: 5000,
      });
    }
  };

  return (
    <>
      <div className="row g-0 mb-4">
        {/* {selectedTag} */}
        <div className="col-12 col-sm-12 col-md-8 col-lg-3">
          <div className="card">
            <div className="card-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h3 className="mb-0">Tags</h3>
                </div>

                <div className="ms-auto">
                  <button className="btn btn-sm btn-primary" onClick={addTag}>
                    Create Tag
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body">
              {isLoading && <Loader height="20vh" />}

              {!isLoading && tagsData?.tags?.length === 0 ? (
                <div className="d-flex align-items-center h-20 justify-content-center">
                  <p className="text-muted mb-0">No Tags Found</p>
                </div>
              ) : (
                <>
                  {tagsData?.tags.map((tag, index) => (
                    <span class="badge rounded-pill bg-primary m-1 font-weight-bold">
                      {tag}
                      {/* create delete icon */}
                      <i
                        class="fe fe-minus ms-1"
                        onClick={() => deleteTag(tag)}
                        style={{ cursor: "pointer" }}
                      ></i>
                    </span>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Modals */}
      <TagForm
        hide={hideTag}
        show={showTagForm}
        tag={tagMode === "add" ? null : selectedTag}
        refetch={refetch}
      />
    </>
  );
};

export default Tags;
