import axios from "axios";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Card from 'react-bootstrap/Card';

const API_URL = process.env.REACT_APP_API_URL;

const SortableItem = SortableElement(({ value }) => {
    return (
        <Card style={{ zIndex: 10000, cursor: 'pointer' }}>
            <Card.Header style={{ borderBottom: 0 }}>
                <p style={{ marginBottom: 0 }}>{value}</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="35" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                </svg>
            </Card.Header>
        </Card>
    );
});

const SortableList = SortableContainer(({ items }) => {
    return (
        <ul style={{ paddingLeft: 0 }}>
            {items.map((item, index) => (
                <SortableItem key={`item-${index}`} index={index} value={item.title} />
            ))}
        </ul>
    );
});

const arrayMove = (array, fromIndex, toIndex) => {
    const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

    if (startIndex >= 0 && startIndex < array.length) {
        const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

        const [item] = array.splice(fromIndex, 1);
        array.splice(endIndex, 0, item);
    }
    return array;
};

class ReorderNotebooks extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            offset: 0,
            limit: 5,
        }
        this.onSortEnd = this.onSortEnd.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleLoadMore = this.handleLoadMore.bind(this);
    }

    componentDidMount() {
        if (this.props.isModalOpen)
            axios.get(`${API_URL}/api/v1/notebooks?page=1&limit=1000`)
            .then(response => {
                const notebooks = response?.data?.data?.notebooks;
                this.setState({ items:notebooks });
            });
    }

    componentDidUpdate(previousProps, previousState) {
        if(this.props.isModalOpen)
            if (previousProps.notebooks.length !== this.state.items.length) {
                axios.get(`${API_URL}/api/v1/notebooks?page=1&limit=1000`)
                .then(response => {
                    const notebooks = response?.data?.data?.notebooks;
                    if(notebooks.length !== previousState.items.length)
                        this.setState({ items: notebooks });
                });
            }
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(({ items }) => ({
            items: arrayMove(items, oldIndex, newIndex),
        }));
    };

    handleUpdate = () => {
        this.props.reorderNotebooks(this.state.items);
    };

    handleLoadMore = () => {
        this.setState({
            ...this.state,
            offset: 0,
            limit: this.state.limit + 5,
        });
    };
    
    render() {
        const items = this.state.items.slice(this.state.offset, this.state.limit);

        return (
            <Modal
                show={this.props.isModalOpen}
                onHide={() => {
                    this.props.closeModal();
                }}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" className="mb-0">
                        Reorder Notebooks
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ paddingTop: '1rem' }}>
                    <small className="form-text pb-2">
                        You can drag and drop the notebooks to re-arrange them.
                    </small>
                    {items.length > 0 && <SortableList
                        items={items}
                        onSortEnd={this.onSortEnd}
                    />}
                    <div className="d-flex justify-content-between">
                        <div> 
                            <button
                                type="button"
                                style={{ borderRadius: 5 }}
                                class="btn btn-outline-primary"
                                onClick={this.handleLoadMore}
                                disabled={this.state.limit >= this.state.items.length}
                            >
                                Load More
                            </button>
                        </div>
                        <div>
                            <button
                                type="button"
                                style={{ borderRadius: 5 }}
                                class="btn btn-outline-danger"
                                onClick={this.props.closeModal}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                style={{ borderRadius: 5, marginLeft: '1rem' }}
                                class="btn btn-outline-primary"
                                onClick={this.handleUpdate}
                            >
                                <span className="fe fe-edit"></span>&nbsp;&nbsp;Save Order
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };
};

export default ReorderNotebooks;