import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: JSON.parse(localStorage.getItem("userProfile")) || null,
    isDark: false,
  },
  reducers: {
    setTheme: (state, action) => {
      state.isDark = action.payload;
    },
    toggleDarkTheme: (state) => {
      state.isDark = !state.isDark;
    },
    setUser: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("userProfile", JSON.stringify(action.payload));
    },
    resetUser: (state) => {
      state.user = null;
      localStorage.removeItem("userProfile");
    },
  },
});

export const { setUser, resetUser, toggleDarkTheme, setTheme } =
  userSlice.actions;

export default userSlice.reducer;
