import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { yupResolver } from "@hookform/resolvers/yup";
import { error, success } from 'tata-js';
import * as Yup from "yup";

import styles from "./auth.module.scss";
import Loader from "../../components/global/Loader";

const API_URL = process.env.REACT_APP_API_URL;
const useQuery = () => new URLSearchParams(useLocation().search);

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .matches(/^[a-zA-Z]+((['. ][a-zA-Z ])?[a-zA-Z]*)*$/, {
      message: "Name should only contain letters and spaces",
    })
    .min(3, "Name should have atleast 3 letters")
    .max(25, " Name should have atmost 25 letters"),
  email: Yup.string().required("Email is required").email("Email is invalid"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const RegisterForm = ({ props }) => {
  const [cookies] = useCookies();
  const [loading, setLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [loginStatus, setLoginStatus] = useState(0);
  const [passVisible, setPassVisible] = useState(false);
  const [passCnfVisible, setPassCnfVisible] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const checkLoginStatus = () => {
      if (cookies["token"]) {
        window.location.href = "/v2/home";
      }
    };
    if (loginStatus === 0) {
      checkLoginStatus();
    }
    return () => { };
  }, [loginStatus]);

  const onSubmit = (data) => {
    setLoading(true);
    const { name, email, password } = data;
    Axios.post(`${API_URL}/api/auth/user/manualRegister`, {
      name,
      email,
      password,
    })
      .then((res) => {
        if (res?.status == 200) {
          setShowDetails(true);
        } else {
          setLoading(false);
          error('Could not create an account', "Please check your email and ensure that you haven't created an account already", {
            position: 'tr',
            duration: 5000,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        error('Could not create an account', "Please check your email and ensure that you haven't created an account already", {
          position: 'tr',
          duration: 5000,
        });
      });
  };

  return (
    <div className={styles.app}>
      {showDetails ? (
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-5 col-xl-4 my-5">
              <h1 class="display-4 text-center mb-3">Email Verification</h1>

              <p class="text-muted text-center mb-4">
                A verification link has been sent to your email. Please follow
                the instructions specified in the email.
              </p>
            </div>
            <div className="text-center">
              <small className="text-muted text-center">
                Having trouble ?
                <br />
                <a href="mailto:support@mathongo.com?subject=I cannot login to Quizrr">
                  {" "}
                  Contact us
                </a>
              </small>
            </div>
          </div>
        </div>
      ) : (
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-5 col-xl-4 my-5">
              <h1 class="display-4 text-center mb-3">Sign up</h1>

              <p class="text-muted text-center mb-5">
                Create a new Quizrr account
              </p>

              <form role="form" onSubmit={handleSubmit(onSubmit)}>
                <div class="form-group">
                  <label class="form-label">Name</label>

                  <input
                    {...register("name")}
                    name="name"
                    type="text"
                    class="form-control"
                  />

                  {errors && errors?.name && (
                    <span className="invalid-feedback d-block mt-2">
                      {errors?.name?.message}
                    </span>
                  )}
                </div>

                <div class="form-group">
                  <label class="form-label">Email Address</label>

                  <input
                    {...register("email")}
                    autoComplete="off"
                    name="email"
                    type="email"
                    class="form-control"
                    placeholder="name@address.com"
                  />

                  {errors && errors?.email && (
                    <span className="invalid-feedback d-block mt-2">
                      {errors?.email?.message}
                    </span>
                  )}
                </div>

                <div class="form-group">
                  <label class="form-label">Password</label>

                  <div class="input-group input-group-merge">
                    <input
                      {...register("password")}
                      class="form-control"
                      type={passVisible ? "text" : "password"}
                      placeholder="Enter your password"
                    />

                    <span
                      class="input-group-text"
                      onClick={() => setPassVisible(!passVisible)}
                    >
                      <i class="fe fe-eye"></i>
                    </span>

                    {errors && errors?.password && (
                      <span className="invalid-feedback d-block mt-2">
                        {errors?.password?.message}
                      </span>
                    )}
                  </div>
                </div>

                <div class="form-group">
                  <label class="form-label">Confirm Password</label>

                  <div class="input-group input-group-merge">
                    <input
                      {...register("confirmPassword")}
                      class="form-control"
                      type={passCnfVisible ? "text" : "password"}
                      placeholder="Enter your password again"
                    />

                    <span
                      class="input-group-text"
                      onClick={() => setPassCnfVisible(!passCnfVisible)}
                    >
                      <i class="fe fe-eye"></i>
                    </span>

                    {errors && errors?.confirmPassword && (
                      <span className="invalid-feedback d-block mt-2">
                        {errors?.confirmPassword?.message}
                      </span>
                    )}
                  </div>
                </div>

                {loading ? (
                  <div class="text-center">
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <button
                    className={`btn btn-lg w-100 btn-primary mb-3 ${!isValid && "disabled"
                      }`}
                  >
                    Sign up
                  </button>
                )}

                <div class="text-center mt-4">
                  <small class="text-muted text-center">
                    Already have an account?{" "}
                    <Link to="/auth/login">Log in</Link>.
                  </small>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const RegisterVerify = ({ props }) => {
  const query = useQuery();
  const [cookies] = useCookies();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loginStatus, setLoginStatus] = useState(0);

  const token = query.get("token");

  useEffect(() => {
    const checkLoginStatus = () => {
      if (cookies["token"]) {
        window.location.href = "/v2/home";
      }
    };
    if (loginStatus === 0) {
      checkLoginStatus();
    }
    return () => { };
  }, [loginStatus]);

  useEffect(() => {
    if (token && token.length > 0) {
      setLoading(true);
      setError(false);
      Axios.post(`${API_URL}/api/auth/user/verifyEmail`, { token })
        .then((res) => {
          setLoading(false);
          console.log(res);
        })
        .catch((err) => {
          setError(true);
          setLoading(false);
          console.log(err);
        });
    } else {
      setError(true);
      setLoading(false);
    }
  }, [token]);

  return (
    <div className={styles.app}>
      {loading ? (
        <Loader />
      ) : (
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-5 col-xl-4 my-5">
              <h1 class="display-4 text-center mb-3">
                {!error ? "Email Verified" : "Email couldn't be verified"}
              </h1>

              {!error ? (
                <p class="text-muted text-center mb-4">
                  Your email has been verified. You can log in to your account
                  from <Link to="/auth/login">here</Link>
                </p>
              ) : (
                <p class="text-muted text-center mb-4">
                  Your email could not be verified. Please check your email for
                  a new verification link or contact us.
                </p>
              )}
            </div>
            <div className="text-center">
              <small className="text-muted text-center">
                Having trouble ?
                <br />
                <a href="mailto:support@mathongo.com?subject=I cannot login to Quizrr">
                  {" "}
                  Contact us
                </a>
              </small>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { RegisterForm, RegisterVerify };
