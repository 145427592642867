import apiClient from "../../utils/apiClient";
import { createSlice } from "@reduxjs/toolkit";
import { ENDPOINTS } from "../../utils/strings";
import { objectToQueryParams } from "../../utils/objectToQuery";

const createInitialStateEntry = () => ({
  data: null,
  loading: false,
  error: null,
});

const packV3FiltersInitialState = {
  search: null,
  testStatus: null,
  testType: null,
  selectUnit: null,
  page: 1,
  limit: 10,
  isFilterApplied: false,
};

const initialState = {
  packV3: createInitialStateEntry(),
  packGroupFilter: createInitialStateEntry(),
  packGroupTests: createInitialStateEntry(),
  v3Filters: {
    ...packV3FiltersInitialState,
  },
  recentPack: JSON.parse(localStorage.getItem("recentPack")) || null,
  pack: createInitialStateEntry(),
  timeLine: createInitialStateEntry(),
  qualityOfAttempt: createInitialStateEntry(),
  difficultyAnalysis: createInitialStateEntry(),
  qsTypeBreakup: createInitialStateEntry(),
  performance: createInitialStateEntry(),
  qualityOfTimeSpent: createInitialStateEntry(),
  subjectWiseTime: createInitialStateEntry(),
  chapterAnalysis: createInitialStateEntry(),
  hourWiseBreakDown: createInitialStateEntry(),
  unitAnalysis: createInitialStateEntry(),
  filters: {
    testFilter: null,
    groupId: null,
    page: 1,
  },
};

const createAsyncAction = (name, stateKey) => {
  return {
    [`fetch${name}`]: (state) => {
      state[stateKey].loading = true;
    },
    [`fetch${name}Success`]: (state, action) => {
      state[stateKey].data = action.payload.data;
      state[stateKey].loading = false;
      state[stateKey].error = null;
    },
    [`fetch${name}Failure`]: (state, action) => {
      state[stateKey].data = null;
      state[stateKey].loading = false;
      state[stateKey].error = action.payload;
    },
  };
};

export const packSlice = createSlice({
  name: "pack",
  initialState: initialState,
  reducers: {
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
    setPage: (state, action) => {
      state.filters.page = action.payload;
    },
    setGroupId: (state, action) => {
      state.filters.page = 1;
      state.filters.groupId = action.payload;
    },
    setTestFilter: (state, action) => {
      state.filters.page = 1;
      state.filters.testFilter = action.payload;
    },
    setRPack: (state, action) => {
      state.recentPack = action.payload;
    },

    updateV3Filters: (state, action) => {
      state.v3Filters = {
        ...state.v3Filters,
        isFilterApplied: true,
        ...action.payload,
      };
    },
    resetV3Filters: (state) => {
      state.v3Filters = {
        ...state.v3Filters,
        ...packV3FiltersInitialState,
      };
    },
    fetchPackGroupTests: (state) => {
      state.packGroupTests.loading = true;
      state.packGroupTests.data = null;
    },
    fetchPackGroupTestsSuccess: (state, action) => {
      state.packGroupTests.data = action.payload.data;
      state.packGroupTests.loading = false;
      state.packGroupTests.error = null;
    },
    fetchPackGroupTestsFailure: (state, action) => {
      state.packGroupTests.data = null;
      state.packGroupTests.loading = false;
      state.packGroupTests.error = action.payload;
    },
    fetchPackGroupFilter: (state) => {
      state.packGroupFilter.loading = true;
      state.packGroupFilter.data = null;
    },
    fetchPackGroupFilterSuccess: (state, action) => {
      state.packGroupFilter.data = action.payload.data;
      state.packGroupFilter.loading = false;
      state.packGroupFilter.error = null;
    },
    fetchPackGroupFilterFailure: (state, action) => {
      state.packGroupFilter.data = null;
      state.packGroupFilter.loading = false;
      state.packGroupFilter.error = action.payload;
    },
    fetchPackV3: (state) => {
      state.packV3.loading = true;
      state.packV3.data = null;
    },
    fetchPackV3Success: (state, action) => {
      state.packV3.data = action.payload.data;
      state.packV3.loading = false;
      state.packV3.error = null;
    },
    fetchPackV3Failure: (state, action) => {
      state.packV3.data = null;
      state.packV3.loading = false;
      state.packV3.error = action.payload;
    },

    fetchPack: (state) => {
      state.pack.loading = true;
      state.pack.data = null;
    },
    fetchPackSuccess: (state, action) => {
      state.pack.data = action.payload.data;
      state.pack.loading = false;
      state.pack.error = null;
    },
    fetchPackFailure: (state, action) => {
      state.pack.data = null;
      state.pack.loading = false;
      state.pack.error = action.payload;
    },
    ...createAsyncAction("TimeLine", "timeLine"),
    ...createAsyncAction("QualityOfAttempt", "qualityOfAttempt"),
    ...createAsyncAction("DifficultyAnalysis", "difficultyAnalysis"),
    ...createAsyncAction("QsTypeBreakup", "qsTypeBreakup"),
    ...createAsyncAction("Performance", "performance"),
    ...createAsyncAction("QualityOfTimeSpent", "qualityOfTimeSpent"),
    ...createAsyncAction("SubjectWiseTime", "subjectWiseTime"),
    ...createAsyncAction("ChapterAnalysis", "chapterAnalysis"),
    ...createAsyncAction("HourWiseAnalysis", "hourWiseBreakDown"),
    ...createAsyncAction("UnitAnalysis", "unitAnalysis"),
  },
});

/**
 *
 * @param {*} actionType we are creating 4 actions for each analysis type
 * @param {*} endpointPart this is the part of the endpoint that is different for each analysis type
 * @returns
 */
const createAnalysisAction =
  (actionType, endpointPart) =>
  ({ packId }) =>
  async (dispatch, getState) => {
    dispatch(packSlice.actions[actionType]());
    const {
      pack: { filters },
    } = getState();
    const filterQuery = objectToQueryParams(filters);
    try {
      const response = await apiClient.get(
        ENDPOINTS.PACK_ANALYSIS.OVERVIEW +
          `/${packId}/${endpointPart}?${filterQuery}&summaryRequired=true`
      );
      dispatch(packSlice.actions[`${actionType}Success`](response.data));
    } catch (error) {
      dispatch(packSlice.actions[`${actionType}Failure`](error));
    }
  };

export const fetchPackUnitAnalysis = createAnalysisAction(
  "fetchUnitAnalysis",
  "unit-analysis"
);

export const fetchPackHourWiseAnalysis = createAnalysisAction(
  "fetchHourWiseAnalysis",
  "hour-wise-breakdown"
);

export const fetchPackChapterAnalysis = createAnalysisAction(
  "fetchChapterAnalysis",
  "chapter-topic-analysis"
);

export const fetchPackTimeLine = createAnalysisAction(
  "fetchTimeLine",
  "timeline"
);
export const fetchPackQualityOfAttempt = createAnalysisAction(
  "fetchQualityOfAttempt",
  "quality-of-attempt"
);
export const fetchPackDifficultyAnalysis = createAnalysisAction(
  "fetchDifficultyAnalysis",
  "difficulty-analysis"
);
export const fetchPackQsTypeBreakup = createAnalysisAction(
  "fetchQsTypeBreakup",
  "question-type-breakup"
);
export const fetchPackPerformance = createAnalysisAction(
  "fetchPerformance",
  "progress"
);
export const fetchPackQualityOfTimeSpent = createAnalysisAction(
  "fetchQualityOfTimeSpent",
  "quality-of-time-spent"
);
export const fetchPackSubjectWiseTime = createAnalysisAction(
  "fetchSubjectWiseTime",
  "subject-wise-time"
);

export const {
  setRPack,
  setTestFilter,
  setGroupId,
  setPage,
  resetFilters,
  updateV3Filters,
  resetV3Filters,
} = packSlice.actions;

export const fetchPack = (id) => async (dispatch, getState) => {
  const {
    pack: { filters },
  } = getState();
  dispatch(packSlice.actions.fetchPack());
  let URL = `/api/packs/v2/analysis/${id}/basic`;
  if (filters.groupId) {
    URL += `?groupId=${filters.groupId}`;
  }
  try {
    const response = await apiClient.get(URL);
    dispatch(packSlice.actions.fetchPackSuccess(response.data));
  } catch (error) {
    dispatch(packSlice.actions.fetchPackFailure(error));
  }
};

export const fetchPackV3 = (id) => async (dispatch) => {
  dispatch(packSlice.actions.fetchPackV3());
  try {
    const response = await apiClient.get(`/api/packs/v2/dashboard/${id}/basic`);
    dispatch(packSlice.actions.fetchPackV3Success(response.data));
  } catch (error) {
    dispatch(packSlice.actions.fetchPackV3Failure(error));
  }
};

export const fetchPackGroupFilter = (packId, groupId) => async (dispatch) => {
  dispatch(packSlice.actions.fetchPackGroupFilter());
  try {
    const response = await apiClient.get(
      `/api/packs/v2/dashboard/${packId}/${groupId}/filters`
    );
    dispatch(packSlice.actions.fetchPackGroupFilterSuccess(response.data));
  } catch (error) {
    dispatch(packSlice.actions.fetchPackGroupFilterFailure(error));
  }
};

export const fetchPackGroupTests =
  (packId, groupId) => async (dispatch, getState) => {
    dispatch(packSlice.actions.fetchPackGroupTests());
    try {
      const {
        pack: { v3Filters },
      } = getState();
      const filtersString = objectToQueryParams(v3Filters);
      const response = await apiClient.get(
        `/api/packs/v2/dashboard/${packId}/${groupId}/tests?${filtersString}`
      );
      dispatch(packSlice.actions.fetchPackGroupTestsSuccess(response.data));
    } catch (error) {
      dispatch(packSlice.actions.fetchPackGroupTestsFailure(error));
    }
  };

export default packSlice.reducer;
