import React, { useEffect } from "react";
import { CookiesProvider } from "react-cookie";
import { Helmet } from "react-helmet";
import { useCookies } from "react-cookie";
import { ClearCacheProvider } from "react-clear-cache";
import { ErrorBoundary } from "react-error-boundary";
import axios from "axios";
import DisableDevtool from "disable-devtool";
import { QueryClient, QueryClientProvider } from "react-query";

import Router from "./router";
import ErrorPage from "./pages/error";
import Push from "./components/global/Push";
import Logger from "./components/global/Logger";
import { ThemeStore } from "./context/ThemeStore";

import "./styles/common.scss";
import { ThemeProvider } from "styled-components";
import { useSelector } from "react-redux";
import { ConfigProvider, theme } from "antd";
import PreparationDetailsModal from "./components/account/v2/PreparationDetailsModal";

const queryClient = new QueryClient();
const UP = JSON.parse(localStorage.getItem("userProfile"));
const isOnboardingV2Done = UP ? UP.isOnboardingV2Done : false;
const isLms = sessionStorage.getItem("lms");

const PATHS_TO_IGNORE = [
  "/onboarding",
  "/auth",
  "/maintenance",
  "/auth/login",
  "/auth/reset/request",
  "/logout",
];

const App = () => {
  const [cookies] = useCookies();
  useEffect(() => {
    const setCookieHeader = async () => {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${cookies["token"]}`;
    };
    setCookieHeader();
  }, []);

  // if (!UP || !UP.email.includes("@mathongo.com")) {
  //   DisableDevtool({
  //     ondevtoolopen: (type) => {
  //       const info = 'Devtools opened! Please close the devtools or open the page in a new tab';
  //       alert(info);
  //     },
  //     disableCopy: window.location.pathname !== "/auth" && window.location.pathname !== "/auth/login",
  //     disableCut: window.location.pathname !== "/auth" && window.location.pathname !== "/auth/login",
  //     disableSelect: window.location.pathname !== "/auth" && window.location.pathname !== "/auth/login",
  //     disablePaste: window.location.pathname !== "/auth" && window.location.pathname !== "/auth/login",
  //     md5: "09bf08f5b203b596a0160120219e6ff5",
  //   });
  // }

  const { isDark } = useSelector((state) => state.user);
  if (
    UP &&
    !isOnboardingV2Done &&
    PATHS_TO_IGNORE.indexOf(window.location.pathname) === -1 &&
    !isLms
  ) {
    window.location.href = "/onboarding";
  }
  return (
    <ErrorBoundary
      onError={(error, componentStack) => {
        console.log({ error, componentStack });
        Logger({ error, componentStack, location: window.location });
      }}
      fallbackRender={({ resetErrorBoundary }) => (
        <ErrorPage type={503} resetErrorBoundary={resetErrorBoundary} />
      )}
    >
      <ClearCacheProvider auto={true}>
        <ThemeStore>
          <Helmet>
            <meta charset="UTF-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="robots" content="imageindex, follow, snippet, index" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            />
            <title>{`IIT, JEE Mains | MathonGo`}</title>
            <link rel="manifest" href="/manifest.json" />
            <link
              href="/icons/favicon-16x16.png"
              rel="icon"
              type="image/png"
              sizes="16x16"
            />
            <link
              href="/icons/favicon-32x32.png"
              rel="icon"
              type="image/png"
              sizes="32x32"
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/apple-touch-icon.png"
            ></link>
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/favicon-32x32.png"
            ></link>
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/favicon-16x16.png"
            ></link>
            <link
              rel="mask-icon"
              href="/safari-pinned-tab.svg"
              color="#2e005b"
            ></link>
            <meta name="theme-color" content="#317EFB" />
            <link
              href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap"
              rel="stylesheet"
            />
          </Helmet>
          <CookiesProvider>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider
                theme={{
                  isDark: isDark,
                }}
              >
                <ConfigProvider
                  theme={{
                    algorithm: isDark
                      ? theme.darkAlgorithm
                      : theme.defaultAlgorithm,
                    token: isDark ? THEME.dark : THEME.light,
                  }}
                >
                  <PreparationDetailsModal/>
                  <Router />
                </ConfigProvider>
              </ThemeProvider>
              <Push />
            </QueryClientProvider>
          </CookiesProvider>
        </ThemeStore>
      </ClearCacheProvider>
    </ErrorBoundary>
  );
};

const THEME = {
  light: {},
  dark: {
    colorBgContainer: "#152e4d",
    colorBgElevated: "#152e4d",
    colorText: "#fff",
  },
};

export default App;
