import axios from 'axios';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import styles from './TimeLine.module.scss';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const TimeLine = () => {
    const [cookies] = useCookies();
    const [tests, setTests] = useState([]);
    const [loading, setLoading] = useState(true);
    const query = useQuery();
    const packId = query.get("packId");
    const history = useHistory();
    const [sort, setSort] = useState("desc");
    const [limit, setLimit] = useState(10);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchTests = async (sortBy, limitCount) => {
        setLoading(true);
        axios
            .get(
                `/api/test/timeline?sort=${sortBy}&packId=${packId}&limit=${limitCount}&offset=0`,
                { headers: { Authorization: `Bearer ${cookies.token}` } }
            )
            .then(
                response => {
                    console.log('Response: ', response)
                    if (response && response.status === 200) {
                        setTests(response.data?.data);
                    }
                }
            )
            .catch(e => console.log('Error: ', e))
            .finally(() => setLoading(false));
    }

    const handleClick = (testId) => {
        history.push(`/${packId}/${testId}`);
    }

    useEffect(() => {
        if (cookies.token) {
            fetchTests(sort, limit)
        }
    }, [cookies.token]);

    const toggleSort = () => {
        fetchTests(sort === "desc" ? "asc" : "desc", limit);
        setSort(sort === "desc" ? "asc" : "desc");
    }

    const loadMore = () => {
        setLimit(limit + 10);
        fetchTests(sort, limit + 10);
    }

    const getTestNumber = (index) => {
        if (sort === "asc") {
            return index + 1
        }
        return tests?.totalItems - index;
    }

    return (
        <>
            <div class="header">
                <div class="container-fluid">
                    <div class="header-body">
                        <div class="row align-items-center">
                            <div class="col-8">
                                <h6 class="header-pretitle text-secondary">Overview</h6>
                                <h1 class="header-title">Your Test Attempt Timeline</h1>
                            </div>
                            <div className="col-4">
                                <div className="d-flex justify-content-end">
                                    <a className="small cursor-pointer" onClick={toggleSort}>Showing: {sort === "desc" ? "Latest to Oldest" : "Oldest to Latest"}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="card card-fill">
                    <div className="card-body">
                        {loading ? (
                            <div className="text-center">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>
                        ) : (
                            <>
                                {tests?.attemptedTests?.length > 0 ? (
                                    <div className="list-group list-group-flush list-group-activity my-n3">
                                        {tests?.attemptedTests?.map((test, testIndex) => (
                                            <div className="list-group-item">
                                                <div className="row">
                                                    <div className="col-auto">

                                                        <div className="avatar avatar-sm">
                                                            <div className={`${styles.numberCard} timeline-pill`}>{getTestNumber(testIndex)}</div>
                                                        </div>

                                                    </div>
                                                    <div className="col ms-n2">

                                                        <h5 className="mb-2 cursor-pointer text-primary" onClick={handleClick.bind(this, test?._id)}>
                                                            {test?.title}
                                                        </h5>

                                                        <small className="text-muted">
                                                            Attempted On: {moment(test?.submittedAt).format("MMM Do YYYY")}
                                                        </small>

                                                        <div className="row mt-3">
                                                            <div className="col">
                                                                <p className='small text-gray-700 mb-0'>
                                                                    <span className='fe fe-award' /> Score: {test?.totalMarkScored}/{test?.totalMarks}
                                                                </p>
                                                            </div>
                                                            <div className="col">
                                                                <p className='small text-gray-700 mb-0'>
                                                                    <span className='fe fe-clock' /> Time Taken: {Math.round(((test?.totalTimeTaken / 60) + Number.EPSILON) * 10) / 10}/{test?.totalTime} min
                                                                </p>
                                                            </div>
                                                            <div className="col">
                                                                <p className='small text-gray-700 mb-0'>
                                                                    <span className='fe fe-edit-3' /> Qs Attempted: {test?.totalAttempted}/{test?.totalQuestions}
                                                                </p>
                                                            </div>
                                                            <div className="col">
                                                                <p className='small text-gray-700 mb-0'>
                                                                    <span className='fe fe-percent' /> Accuracy: {Math.round((test?.accuracy + Number.EPSILON) * 100) / 100}
                                                                </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="text-center text-muted">
                                        <small className="text-muted">
                                            You've not attempted any tests. 🙄
                                        </small>
                                        <br />
                                        <Link
                                            className="btn btn-danger mt-3 mb-2 btn-sm"
                                            to="/test-series"
                                        >
                                            View All Packs
                                        </Link>
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                    <div className="card-footer text-center">
                        {/* Create load more button in the center */}
                        <button
                            className="btn btn-sm btn-primary"
                            onClick={loadMore}
                            disabled={tests?.pages * 10 < limit + 10}
                        >
                            Load More
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TimeLine;
