import React from "react";
import { Modal, Button } from "react-bootstrap";

const PreparationDetailsV2ReasonModal = ({
  setShowExplanation,
  showExplanation,
}) => {
  const handleClose = () => {
    setShowExplanation(false);
  };

  return (
    <Modal
      centered
      show={showExplanation}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>Why are we asking for these details?</Modal.Header>
      <Modal.Body>
        By sharing your current preparation details with us, we will be able to
        provide more tailored guidance and mentorship, ensuring that our support
        is perfectly aligned with your unique needs and goals on this journey.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PreparationDetailsV2ReasonModal;
