import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { yupResolver } from "@hookform/resolvers/yup";
import { error, success } from 'tata-js';
import * as Yup from "yup";

import styles from "./auth.module.scss";
import Loader from "../../components/global/Loader";

const API_URL = process.env.REACT_APP_API_URL;
const useQuery = () => new URLSearchParams(useLocation().search);

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const ResetRequest = ({ props }) => {
  const [cookies] = useCookies();
  const [loading, setLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [loginStatus, setLoginStatus] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange", reValidateMode: "onChange" });

  useEffect(() => {
    const checkLoginStatus = () => {
      if (cookies["token"]) {
        window.location.href = "/v2/home";
      }
    };
    if (loginStatus === 0) {
      checkLoginStatus();
    }
    return () => { };
  }, [loginStatus]);

  const onSubmit = (data) => {
    setLoading(true);
    const { email } = data;
    Axios.post(`${API_URL}/api/auth/user/send-password`, {
      email,
    }).then((res) => {
      if (res?.status === 200) {
        setShowDetails(true);
        setLoading(false);
      } else {
        error('Error', 'Ensure that you have entered the correct email', {
          position: 'tr',
          duration: 5000,
        });
        setLoading(false);
      }
    });
  };

  return (
    <div className={styles.app}>
      {showDetails ? (
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-5 col-xl-4 my-5">
              <h1 class="display-4 text-center mb-3">Forgot Password</h1>

              <p class="text-muted text-center mb-3">
                The password has been sent to your email. Please
                follow the instructions specified in the email.
              </p>
            </div>
            <div className="text-center">
              <small className="text-muted text-center">
                Having trouble ?
                <br />
                <a href="mailto:support@mathongo.com?subject=I cannot login to Quizrr">
                  {" "}
                  Contact us
                </a>
              </small>
            </div>
          </div>
        </div>
      ) : (
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-5 col-xl-4 my-5">
              <h1 class="display-4 text-center mb-3">Forgot Password</h1>

              <p class="text-muted text-center mb-5">
                Enter your registered email to get the password over email.
              </p>

              <form role="form" onSubmit={handleSubmit(onSubmit)}>
                <div class="form-group">
                  <label class="form-label">Email Address</label>
                  <input
                    {...register("email", {
                      required: true,
                      pattern:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                    autoComplete="off"
                    name="email"
                    type="email"
                    class="form-control"
                    placeholder="name@address.com"
                  />

                  {errors && errors?.email?.type == "required" && (
                    <span className="invalid-feedback d-block mt-2">
                      This field is required
                    </span>
                  )}
                  {errors && errors?.email?.type == "pattern" && (
                    <span className="invalid-feedback d-block mt-2">
                      Please enter a valid email
                    </span>
                  )}
                </div>

                {loading ? (
                  <div class="text-center">
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <button
                    className={`btn btn-lg w-100 btn-primary mb-3 ${!isValid && "disabled"
                      }`}
                  >
                    Receive Password on Email
                  </button>
                )}

                <div class="text-center mt-4">
                  <small class="text-muted text-center">
                    Remember your password? <Link to="/auth/login">Log in</Link>
                    .
                  </small>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ResetConfirm = ({ props }) => {
  const query = useQuery();
  const [cookies] = useCookies();
  const [loginStatus, setLoginStatus] = useState(0);
  const [error, setError] = useState(false);
  const [finished, setFinished] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [passVisible, setPassVisible] = useState(false);
  const [passCnfVisible, setPassCnfVisible] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const token = query.get("token");

  useEffect(() => {
    const checkLoginStatus = () => {
      if (cookies["token"]) {
        window.location.href = "/v2/home";
      }
    };
    if (loginStatus === 0) {
      checkLoginStatus();
    }
    return () => { };
  }, [loginStatus]);

  useEffect(() => {
    if (token && token.length > 0) {
      setLoading(true);
      Axios.post(`${API_URL}/api/auth/user/resetPassVerify`, { token })
        .then((res) => {
          if (res?.status === 200) {
            setLoading(false);
          } else {
            setError(true);
            setLoading(false);
          }
        })
        .catch((err) => {
          setError(true);
          setLoading(false);
        });
    }
  }, [token]);

  const onSubmit = (data) => {
    setFormLoading(true);
    const { password } = data;
    Axios.patch(`${API_URL}/api/auth/user/resetPassConfirm`, {
      token,
      newPassword: password,
    })
      .then((res) => {
        setFinished(true);
      })
      .catch((err) => {
        setError(true);
      });
  };

  return (
    <div className={styles.app}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {error ? (
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 col-md-5 col-xl-4 my-5">
                  <h1 class="display-4 text-center mb-3">
                    Reset Password Error
                  </h1>
                  <p class="text-muted text-center mb-4">
                    An error occured while resetting your password. Your
                    password has not been changef. Please check your email for a
                    new reset password link or contact us.
                  </p>
                </div>
                <div className="text-center">
                  <small className="text-muted text-center">
                    Having trouble ?
                    <br />
                    <a href="mailto:support@mathongo.com?subject=I cannot login to Quizrr">
                      {" "}
                      Contact us
                    </a>
                  </small>
                </div>
              </div>
            </div>
          ) : (
            <>
              {finished ? (
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-12 col-md-5 col-xl-4 my-5">
                      <h1 class="display-4 text-center mb-3">
                        Password Changed
                      </h1>

                      <p class="text-muted text-center mb-4">
                        Your email has been successfully changed. You can log in
                        to your account from <Link to="/auth/login">here</Link>
                      </p>
                    </div>
                    <div className="text-center">
                      <small className="text-muted text-center">
                        Having trouble ?
                        <br />
                        <a href="mailto:support@mathongo.com?subject=I cannot login to Quizrr">
                          {" "}
                          Contact us
                        </a>
                      </small>
                    </div>
                  </div>
                </div>
              ) : (
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-12 col-md-5 col-xl-4 my-5">
                      <h1 class="display-4 text-center mb-3">
                        Set a New Password
                      </h1>

                      <p class="text-muted text-center mb-5">
                        Please enter and confirm your new password
                      </p>

                      <form role="form" onSubmit={handleSubmit(onSubmit)}>
                        <div class="form-group">
                          <label class="form-label">Password</label>

                          <div class="input-group input-group-merge">
                            <input
                              {...register("password")}
                              class="form-control"
                              type={passVisible ? "text" : "password"}
                              placeholder="Enter your password"
                            />

                            <span
                              class="input-group-text"
                              onClick={() => setPassVisible(!passVisible)}
                            >
                              <i class="fe fe-eye"></i>
                            </span>

                            {errors && errors?.password && (
                              <span className="invalid-feedback d-block mt-2">
                                {errors?.password?.message}
                              </span>
                            )}
                          </div>
                        </div>

                        <div class="form-group">
                          <label class="form-label">Confirm Password</label>

                          <div class="input-group input-group-merge">
                            <input
                              {...register("confirmPassword")}
                              class="form-control"
                              type={passCnfVisible ? "text" : "password"}
                              placeholder="Enter your password again"
                            />

                            <span
                              class="input-group-text"
                              onClick={() => setPassCnfVisible(!passCnfVisible)}
                            >
                              <i class="fe fe-eye"></i>
                            </span>

                            {errors && errors?.confirmPassword && (
                              <span className="invalid-feedback d-block mt-2">
                                {errors?.confirmPassword?.message}
                              </span>
                            )}
                          </div>
                        </div>

                        {formLoading ? (
                          <div class="text-center">
                            <div
                              class="spinner-border text-primary"
                              role="status"
                            >
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          <button
                            className={`btn btn-lg w-100 btn-primary mb-3 ${!isValid && "disabled"
                              }`}
                          >
                            Set New Password
                          </button>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export { ResetRequest, ResetConfirm };
