import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import Cookies from "js-cookie";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import store from "./redux/store";
import App from "./App";

import "./index.css";

const persistor = persistStore(store);

// Axios Global Configuration
const API_URL = process.env.REACT_APP_API_URL;
const token = Cookies.get("token");
axios.defaults.baseURL = `${API_URL}`;
axios.defaults.headers.post["Content-Type"] = "application/json";
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

// 401 interceptor - redirect to auth page if 401 status
axios.interceptors.response.use(
  (success) => {
    return success;
  },
  (error) => {
    const res = error?.response
      ? error?.response?.data?.error?.errorCode
      : null;

    if (res === 401) {
      if (window.location.pathname !== "/auth") {
        Cookies.remove("token");
        window.location = "/auth";
      }
    }
  }
);


ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<div>Loading</div>} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
