import React from "react";
import { ButtonGrid } from "./PreparationDetailsFormV2";
import styled from "styled-components";
import { useForm } from "react-hook-form";

const DropperDetailsFormV2 = ({ values, year, onSubmit, handlePrev }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: values,
  });

  const onSubmitForm = handleSubmit((data) => {
    onSubmit(data);
  });
  return (
    <div>
      <form onSubmit={onSubmitForm}>
        <TopHeader className="mb-2">
          Please fill your JEE Main {year} Details{" "}
        </TopHeader>
        <div>
          <Label>What was your All India Rank?</Label>
          <Input
            type="number"
            pattern="\d+" // Add pattern to accept only whole numbers
            min={0}
            step={1} // Add step prop to accept only whole numbers
            placeholder="Your rank"
            {...register("jeeMainRank", {
              required: true,
              onChange: (e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 6);
              },
            })}
            className="form-control"
          />
          {errors && errors.jeeMainRank && (
            <small className="text-danger">This field is required</small>
          )}
        </div>
        <div className="mt-3">
          <Label>What was your overall percentile?</Label>
          <Input
            type="number"
            min={0}
            max={100}
            step={"any"}
            pattern="\d+" // Add pattern to accept only whole numbers
            placeholder="Your overall percentile"
            {...register("jeeMainOverallPercentile", { required: true })}
            className="form-control"
          />
          {errors && errors.jeeMainOverallPercentile && (
            <small className="text-danger">This field is required</small>
          )}
        </div>
        <hr />
        <TopHeader>What was your overall percentile in </TopHeader>
        <div className="mt-2">
          <PhysicsLabel>Physics</PhysicsLabel>
          <Input
            type="number" // Change type to "text" to accept any input
            min={0}
            max={100}
            step={"any"}
            placeholder="Physics percentile"
            {...register("jeeMainPhysicsPercentile", { required: true })}
            className="form-control"
          />
          {errors && errors.jeeMainPhysicsPercentile && (
            <small className="text-danger">This field is required</small>
          )}
        </div>
        <div className="my-3">
          <ChemistryLabel>Chemistry</ChemistryLabel>
          <Input
            type="number" // Change type to "text" to accept any input
            min={0}
            max={100}
            step={"any"}
            placeholder="Chemistry percentile"
            {...register("jeeMainChemistryPercentile", { required: true })}
            className="form-control"
          />
          {errors && errors.jeeMainChemistryPercentile && (
            <small className="text-danger">This field is required</small>
          )}
        </div>
        <div>
          <MathsLabel>Mathematics</MathsLabel>
          <Input
            type="number" // Change type to "text" to accept any input
            min={0}
            max={100}
            step={"any"}
            placeholder="Mathematics percentile"
            {...register("jeeMainMathematicsPercentile", { required: true })}
            className="form-control"
          />
          {errors && errors.jeeMainMathematicsPercentile && (
            <small className="text-danger">This field is required</small>
          )}
        </div>
        <ButtonGrid showPrev={false}>
          <button type="submit">Submit</button>
        </ButtonGrid>
      </form>
    </div>
  );
};

export default DropperDetailsFormV2;

const TopHeader = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 0.5rem;
`;

const PhysicsLabel = styled.label`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: var(--Subject-physics, #60f4a6);
`;

const ChemistryLabel = styled.label`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: var(--Subject-Chemistry-0, #ff8c45);
`;

const MathsLabel = styled.label`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: var(--Subject-Mathematics-0, #0084f9);
`;
