import axios from "axios";
import React, { useState, useEffect } from "react";

const API_URL = process.env.REACT_APP_API_URL;

function Searchbar({ searchValue, setSearchValue, setNotebooks }) {
  
  const handleSearch = async () => {
    const response = await axios.get(`${API_URL}/api/v1/notebooks/search?filter=${searchValue}`);
    setNotebooks(response?.data.notebooks);
  };
  
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };
  
  const handleReset = () => {
    setSearchValue("");
  };
  
  return (
    <div>
        <div className="row">
            <div className="col-12 right-border1">
                <div className="card">
                    <div className="card-header"
                        style={{
                            borderBottom: 0,
                            paddingLeft: 12,
                            paddingRight: 12
                        }}
                    >
                        <div className="d-flex input-group-flush input-group-merge">
                            <input
                                className="form-control list-search"
                                type="search"
                                placeholder="Search Notebooks"
                                value={searchValue}
                                onChange={handleSearchChange}
                            />
                            <button
                                type="button"
                                style={{ borderRadius: 5, marginLeft: '1rem' }}
                                class="btn btn-outline-primary"
                                disabled={searchValue.trim() === ""}
                                onClick={handleSearch}
                            >
                                <span className="fe fe-search"></span>&nbsp;&nbsp;Search
                            </button>
                            {searchValue.trim() !== "" && (
                            <button
                                type="button"
                                class="btn btn-outline-danger"
                                style={{ borderRadius: 5, marginLeft: "1rem", whiteSpace: "nowrap" }}
                                disabled={searchValue.trim() === ""}
                                onClick={handleReset}
                            >
                                <span className="fe fe-refresh-cw"></span>&nbsp;Clear Search
                            </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Searchbar;