var firebaseConfig = {
    apiKey: "AIzaSyBnsTY4T57vaZVvL-Ki9K2_4YABLdl0XCM",
    authDomain: "getmarksid.firebaseapp.com",
    projectId: "getmarksid",
    storageBucket: "getmarksid.appspot.com",
    messagingSenderId: "868834406277",
    appId: "1:868834406277:web:615c1e4890f2b043785eec",
    measurementId: "G-2CSKCPP5CJ"
};

module.exports = { firebaseConfig }