import React from "react";
import styled from "styled-components";

const PackCardV3 = ({
  icon,
  title,
  pack,
  setPack,
  _id,
  handleViewPack,
  version,
}) => {
  const handleViewTestSeriesClick = () => {
    handleViewPack(_id, version);
  };

  return (
    <div
      className="card cursor-pointer"
      onClick={handleViewTestSeriesClick}
    >
      <div className="card-body p-4">
        <div className="row">
          <div className="col-2 px-1">
            <img width="30" height="30" src={icon} alt={title} />
          </div>
          <div className="col-9 px-1 my-auto">
            <h2 className="mb-0" style={{ fontSize: "14px" }}>
              {title}
            </h2>
          </div>
        </div>
        <PackCardIcon
          src="/img/keyboard-arrow-right.svg"
          alt=">"
          className="fe fe-arrow-right"
        />
      </div>
    </div>
  );
};

export default PackCardV3;

const PackCardIcon = styled.span`
  position: absolute;
  top: 24px;
  right: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
`;
