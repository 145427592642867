import React from "react";
import MathJax from "react-mathjax-preview";

const LatexCustom = ({ children }) => {
  let text = children;
  text = text?.replace(/\n/g, "<br>");
  return <MathJax math={text} />;
};

export default LatexCustom;
