import React, { useState, useEffect, useContext } from 'react';
import firebase from 'firebase';
import { ThemeContext } from '../../context/ThemeStore';
import { firebaseConfig } from '../../utils/firebase-push/config';

import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

const Push = () => {
  const [show, setShow] = useState(false);
  const [isTokenFound, setTokenFound] = useState(false);
  const { showNotification, setShowNotification } = useContext(ThemeContext);

  useEffect(() => {
    (async () => {
      !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
      const fb = firebase.messaging();
      let FCM_TOKEN = null;
      try {
        FCM_TOKEN = await fb.getToken({ vapidKey: process.env.REACT_APP_FB_VAPID_KEY });
      } catch {
        console.log('Error in getting fcm token');
      }

      const isSubscribedToNotification = localStorage.getItem('isSubscribedToNotifications');

      // Subscribe to Global notifications
      if (!isSubscribedToNotification) {
        axios
          .post(`${API_URL}/api/v1/notifications/subscribe/global`, { FCM_TOKEN })
          .then((response) => {
            localStorage.setItem('isSubscribedToNotifications', true);
          })
          .catch((err) => {
            console.log('Failed to subscribe.');
          });
      }

      fb.onMessage((data) => {
        console.log(data);
        setShowNotification(true);
      });
    })();
    return () => {};
  }, []);

  return <div></div>;
};

export default Push;
