import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Dashboard from "../../layout/dashboard";
import Meta from "../../components/global/Meta";
import axios from "axios";
import PackCardV3 from "../../components/v3/PackCardV3";
import PackCardPaid from "../../components/v3/PackCardPaid";

import styles from "./HomePage.module.scss";
import { useHistory } from "react-router-dom";
import HelloBar from "../../components/global/HelloBar";

const userProfile = JSON.parse(localStorage.getItem("userProfile"));
const API_URL = process.env.REACT_APP_API_URL;

const HomePageV3 = () => {
  const history = useHistory();
  const [freePacks, setFreePacks] = useState([]);
  const [purchasedPacks, setPurchasedPacks] = useState([]);
  const [notPurchasedPacks, setNotPurchasedPacks] = useState([]);

  useEffect(() => {
    console.log('inside useefect of homepage')
    axios
      .get(`${API_URL}/api/packs/v3`)
      .then(({ status, data }) => {
        if (status === 200) {
          setPurchasedPacks(data.data?.purchasedPacks);
          setNotPurchasedPacks(data.data?.notPurchasedPacks);
          setFreePacks(data.data?.freePacks);
        }
      })
      .catch((err) => {
        console.log(err);
      });
      console.log(window.webengage)
    if (window.webengage) {
      window.webengage.track("Viewed Dashboard", {
        "User Id": userProfile?.email,
      });
    }
  }, []);

  const handleViewPack = (packId, version) => {
    if (version === 3) {
      return history.push(`/packs/${packId}`);
    }
    history.push(`/v2/dash?packId=${packId}`);
  };
  return (
    <Dashboard>
      <Meta title="Dashboard" />
      <HelloBar />

      <MainContainer>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-2 text-primary fw-bold">
                {formatGreeting()}, {userProfile?.googleName}!
              </h2>
              <p className="text-muted">
                Welcome back! All the best because #PaperPhodnaHai
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <h2>Your test series</h2>
            </div>
          </div>
          
          <div className="row g-3">
            {purchasedPacks?.length > 0 ? (
              purchasedPacks?.map((pack) => (
                <div key={pack?._id} className="col-12 col-sm-6 col-lg-4 col-xl-3">
                  <PackCardV3 {...pack} handleViewPack={handleViewPack} />
                </div>
              ))
            ) : (
              <div className="col-12">
                <EmptyState>
                  Looks like you haven't purchased any test packs. Explore and buy
                  packs from below.
                </EmptyState>
              </div>
            )}
          </div>

          {notPurchasedPacks?.length > 0 && (
            <>
              <div className="row mt-4">
                <div className="col-12">
                  <h2>Explore other test series</h2>
                </div>
              </div>
              <div className="row g-3">
                {notPurchasedPacks?.map((pack) => (
                  <div key={pack?._id} className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <PackCardPaid {...pack} handleViewPack={handleViewPack} />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </MainContainer>
    </Dashboard>
  );
};

const MainContainer = styled.div`
  padding: 2rem 0;
  max-width: 100%;
  overflow-x: hidden;
`;

const EmptyState = styled.div`
  padding: 1.5rem;
  text-align: center;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #e3ebf6;
`;

const ExamCard = styled.div`
  display: flex;
  width: 100%;
  padding: 1.5rem;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  margin-bottom: 1.25rem;
  transition: all 0.3s ease-in-out;
  border-radius: 12px;
  border: 1px solid var(--grayscale-light, #e3ebf6);
  background: #fff;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
`;

const formatGreeting = () => {
  const today = new Date();
  const hour = today.getHours();
  if (hour < 12) {
    return "Good Morning";
  } else if (hour < 16) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
};

export default HomePageV3;
