import Axios from "axios";

class RequestQueue {
  constructor() {
    // Load the queue from localStorage or initialize as an empty array if not present
    this.queue = JSON.parse(localStorage.getItem("requestQueue")) || [];
    window.addEventListener("online", () => this.processQueue());
  }

  enqueue(requestConfig, dispatch, onSuccess, onFailure, retries = 3) {
    this.queue.push({ requestConfig, dispatch, onSuccess, onFailure, retries });
    // Save the updated queue to localStorage
    localStorage.setItem("requestQueue", JSON.stringify(this.queue));
    if (this.queue.length === 1 && navigator.onLine) {
      this.processQueue();
    }
  }

  async processQueue() {
    if (!navigator.onLine) {
      console.log("Offline, queue processing paused.");
      return;
    }
    while (this.queue.length > 0) {
      const { requestConfig, dispatch, onSuccess, onFailure, retries } =
        this.queue[0];
      try {
        const response = await this.retry(requestConfig, retries);
        if (!response) {
          throw new Error("No response received");
        }
        onSuccess(response, dispatch);
        console.log("Queue processed successfully.");
        this.queue.shift(); // Remove the processed request from the queue
        // Save the updated queue to localStorage
        localStorage.setItem("requestQueue", JSON.stringify(this.queue));
      } catch (error) {
        if (onFailure) onFailure(error, dispatch);
        this.queue.shift(); // Remove the failed request from the queue
        // Save the updated queue to localStorage
        localStorage.setItem("requestQueue", JSON.stringify(this.queue));
      }
    }
  }

  async retry(requestConfig, retries) {
    for (let attempt = 1; attempt <= retries; attempt++) {
      if (!navigator.onLine) {
        console.log("Offline, retry paused. Will retry when online.");
        return;
      }
      try {
        return await Axios(requestConfig);
      } catch (error) {
        if (attempt === retries) throw error;
        await this.delay(1000 * attempt);
      }
    }
  }

  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}

export const requestQueue = new RequestQueue();
