import axios from "axios";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { error, success } from 'tata-js';

const TagForm = ({ tag, show, hide, refetch }) => {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (tag) {
      setValue("tag", tag);
    } else {
      setValue("tag", "");
    }
  }, [tag]);

  const saveTag = (data) => {
    const tagValue = getValues().tag;
    if (!tagValue) return;
    setLoading(true);
    axios
      .post(`/api/v1/tags`, {
        name: getValues().tag,
      })
      .then((res) => {
        setLoading(false);
        refetch();
        hide();
        success('Tag Created', "Tag created successfully", {
          position: 'tr',
          duration: 5000,
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          hide();
          reset();
        }}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" className="mb-0">
            {tag ? "Edit tag" : "Create tag"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* Create form with single input text field */}
          <form onSubmit={handleSubmit(saveTag)}>
            <div className="form-group">
              <label htmlFor="tagField">Tag Name</label>
              <input
                type="text"
                className="form-control my-2"
                id="tagField"
                aria-describedby="tagHelp"
                placeholder="Enter Tag Name"
                {...register("tag", { required: true })}
                autoFocus
              />
              <small id="tagField" className="form-text text-muted">
                Make sure to give your Tag a proper name.
              </small>

              {errors && errors.tag && (
                <small className="text-danger">
                  {errors.tag.type === "required" && "Name is required"}
                </small>
              )}
            </div>

            <input
              disabled={loading}
              className="btn btn-primary btn-block"
              type="submit"
            />
          </form>
        </Modal.Body>

        {/* <Modal.Footer className="py-2">
          <button
            disabled={loading}
            className="btn btn-primary btn-block"
            onClick={saveTag}
          >
            Save
          </button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default TagForm;
