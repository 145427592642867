import axios from "axios";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import Loader from "../../components/global/Loader";
import { success } from 'tata-js';
import { useCookies } from "react-cookie";

import NotebookForm from "../../components/notebook/NotebookForm";
import ShowMore from "../../components/global/ShowMore";
import SingleLineQuestionView from "../../components/notebook/SingleLineQuestionView";
import Searchbar from "../../components/notebook/Searchbar";
import ReorderNotebooks from "../../components/notebook/ReorderNotebooks";
import QuestionView from "../../components/notebook/QuestionView";
import DeleteConfirmationModal from "../../components/notebook/DeleteConfirmationModal";

const API_URL = process.env.REACT_APP_API_URL;

const GET_NOTEBOOKS = `${API_URL}/api/v1/notebooks`;
const fetchNotebooks = async (pagination, notebooks, setNotebooks) => {
  const response = await axios.get(
    `${GET_NOTEBOOKS}?page=${pagination.page}&limit=${pagination.limit}`
  );
  setNotebooks(response?.data?.data?.notebooks);
  return response.data;
};

const NoteBooks = () => {
  const [cookies] = useCookies();
  const user = JSON.parse(localStorage.getItem("userProfile"));

  const [notebooks, setNotebooks] = useState([]);
  const [selectedNotebook, setSelectedNotebook] = useState(null);
  const [showNotebookForm, setShowNotebookForm] = useState(false);
  const [notebookMode, setNotebookMode] = useState("add");
  const [notebookShowMore, setNotebookShowMore] = useState({
    hasNextPage: false,
    page: 1,
    limit: 10,
  });
  const [questionShowMore, setQuestionShowMore] = useState({
    hasNextPage: false,
    page: 1,
    limit: 10,
  });
  const [searchValue, setSearchValue] = useState("");
  const [isReorderModalOpen, setIsReorderModalOpen] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [notebookToArchive, setNotebookToArchive] = useState(null);
  const [selectedNotebookQuestions, setSelectedNotebookQuestions] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState("all");
  const [selectedChapter, setSelectedChapter] = useState("all");
  const [allSubjects, setAllSubjects] = useState([]);
  const [allChapters, setAllChapters] = useState([]);

  const handleSubjectChange = (event) => {
    const subjectId = event.target.value;
    setSelectedSubject(subjectId);
    fetchNotebook(subjectId, selectedChapter);
  };

  const handleChapterChange = (event) => {
    const chapterId = event.target.value;
    setSelectedChapter(chapterId);
    fetchNotebook(selectedSubject, chapterId);
  };


  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const initiateArchiveNotebook = (notebook) => {
    setNotebookToArchive(notebook);
    toggleDeleteModal();
  };
  
  const confirmArchiveNotebook = async () => {
    if (!notebookToArchive) return;
    await archiveNotebook(notebookToArchive);
    toggleDeleteModal();
  };

  const isNotebooksFiltered = searchValue.trim() !== "";

  const {
    data: notebookData,
    refetch,
    isLoading,
    isFetching,
  } = useQuery(
    [GET_NOTEBOOKS, notebookShowMore.page, notebookShowMore.limit],
    () => fetchNotebooks(notebookShowMore, notebooks, setNotebooks),
    { keepPreviousData: true, enabled: !isNotebooksFiltered }
  );

  const fetchNotebook = async (subjectId = "all", chapterId = "all") => {
    if (!selectedNotebook) return {};

    const subjectParam = subjectId !== "all" ? `&subjectId=${subjectId}` : "";
    const chapterParam = chapterId !== "all" ? `&chapterId=${chapterId}` : "";
    const response = await axios.get(
      `${GET_NOTEBOOKS}/${selectedNotebook?._id}?page=${questionShowMore.page}&limit=${questionShowMore.limit}${subjectParam}${chapterParam}`
    );

    setSelectedNotebookQuestions(response?.data?.data);
    // Extract all unique subjects and chapters using Maps
    if (chapterId === "all") {
      const chaptersMap = new Map();
      response?.data?.data.forEach(question => {
        question.questionId?.chapters?.forEach(chapter => 
          chaptersMap.set(chapter._id, chapter)
        );
      });
      setAllChapters(Array.from(chaptersMap.values()));
    }

    if (subjectId === "all") {
      const subjectsMap = new Map();
      
      response?.data?.data?.forEach(question => {
        question.questionId?.subjects?.forEach(subject => 
          subjectsMap.set(subject._id, subject)
        );
      });
      
      setAllSubjects(Array.from(subjectsMap.values()));
    }

    return response.data;
  };
  

  const {
    data: selectedNotebookData,
    isLoading: selectedNotebookLoading,
    refetch: refetchNotebook,
    isFetching: isFetchingQuestion,
  } = useQuery(
    [
      `${GET_NOTEBOOKS}/${selectedNotebook?._id}`,
      questionShowMore.page,
      questionShowMore.limit,
      selectedSubject,
      selectedChapter,
    ],
    () => fetchNotebook(selectedSubject, selectedChapter),
    { keepPreviousData: true }
  );

  const deleteNotebook = async (notebookId) => {
    if (!notebookId) return;
    await axios.delete(`${GET_NOTEBOOKS}/${notebookId}`);
    refetch();
    setSelectedNotebook(null);
    success('Notebook Deleted', "Notebook deleted successfully", {
      position: 'tr',
      duration: 5000,
    });
  };

  useEffect(() => {
    if (window.webengage) {
      window.webengage.track("Viewed Notebooks", { "User Id": user?.email });
    }
  }, []);

  useEffect(() => {
    setNotebookShowMore({
      ...notebookShowMore,
      count: notebookData?.count,
      hasNextPage: notebookData?.hasNextPage,
    });
  }, [notebookData]);

  useEffect(() => {
    if (selectedNotebookData) {
      setQuestionShowMore({
        ...questionShowMore,
        count: selectedNotebookData?.count,
        hasNextPage: selectedNotebookData?.hasNextPage,
      });
    }
  }, [selectedNotebookData]);

  const toggleNotebookForm = () => setShowNotebookForm(!showNotebookForm);
  const addNotebook = () => {
    setNotebookMode("add");
    setShowNotebookForm(true);
  };
  const editNotebook = (notebook) => {
    setSelectedNotebook(notebook);
    setNotebookMode("edit");
    toggleNotebookForm();
  };

  const archiveNotebook = async (notebook) => {
    if (!notebook) return;
    const notebookId = notebook._id;
    await axios.patch(`${GET_NOTEBOOKS}/${notebookId}/archive`);
    refetch();
    setSelectedNotebook(null);
    success("Notebook Deleted", "Notebook deleted Successfully!", {
      position: "tr",
      duration: 5000,
    });
  };

  const openReorderModal = () => {
    setIsReorderModalOpen(true);
  };

  const closeReorderModal = () => {
    setIsReorderModalOpen(false);
  };

  const reorderNotebooks = async (items) => {
    const notebooks = items.map((notebook, index) => {
      const originalPosition = notebook.position;
      const newPosition = index;
      notebook.originalPosition = originalPosition;
      notebook.newPosition = newPosition;
      return notebook;
    });

    await axios.post(`${API_URL}/api/v1/notebooks/reorder`, notebooks, {
      headers: { Authorization: `Bearer ${cookies["token"]}` },
    });
    closeReorderModal();
    refetch();
    success('Notebooks Rearranged', 'Notebooks have been rearranged successfully', {
      position: 'tr',
      duration: 5000,
    });
  };

  const viewQuestion = (question, questionIndex) => {
    setSelectedQuestion(question);
    setShowQuestion(true);
    setCurrentIndex(questionIndex);
  };
  const hideViewQuestion = () => {
    setShowQuestion(false);
  };

  return (
    <>
      <NotebookForm
        notebook={notebookMode === "edit" ? selectedNotebook?.title : null}
        notebookId={selectedNotebook?._id}
        show={showNotebookForm}
        hide={toggleNotebookForm}
        refetch={refetch}
      />
      {notebooks?.length > 0 && (
        <ReorderNotebooks
          isModalOpen={isReorderModalOpen}
          closeModal={closeReorderModal}
          notebooks={notebooks}
          reorderNotebooks={reorderNotebooks}
        />
      )}
      {/* {JSON.stringify(notebooks, null, 2)} */}
      <Searchbar
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setNotebooks={setNotebooks}
      />
      <div className="row">
        <div className="col-12 col-xl-3 right-border1">
          <div className="card">
            <div className="card-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h4 className="mb-0">Notebooks</h4>
                </div>

                <div className="ms-auto">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={openReorderModal}
                  >
                    Reorder
                  </button>

                  <button
                    className="btn btn-sm btn-danger"
                    onClick={addNotebook}
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    Add New
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="list-group h-20">
                {isLoading && <Loader height="20vh" />}
                {!isLoading && notebooks?.length === 0 ? (
                  <div className="d-flex align-items-center h-20 justify-content-center">
                    <p className="text-muted mb-0">No Notebooks Found</p>
                  </div>
                ) : (
                  <>
                    {notebooks?.map((notebook, index) => (
                      <button
                        key={index}
                        className={`list-group-item list-group-item-action z1 ${
                          selectedNotebook &&
                          selectedNotebook?._id === notebook?._id
                            ? `text-primary`
                            : ""
                        }`}
                        onClick={() => {
                          setSelectedNotebook(notebook);
                          setSelectedSubject("all");
                          setSelectedChapter("all");
                        }}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <h5 className="mb-0">{notebook?.title + " "}</h5>
                          </div>

                          <div className="d-flex">
                            <div
                              className="ms-3"
                              onClick={editNotebook.bind(this, notebook)}
                            >
                              <span className={`fe fe-edit`}></span>
                              <p className="d-inline ms-1">Edit</p>
                            </div>

                            <div
                              lassName="ms-3"
                              onClick={(e) => {
                                e.stopPropagation();
                                initiateArchiveNotebook(notebook);
                              }}
                            >
                              <span className={`fe fe-trash`}></span>
                              <p className="d-inline ms-1">Delete</p>
                            </div>
                          </div>
                        </div>
                      </button>
                    ))}

                    {isFetching && <Loader height="20vh" />}

                    {!isNotebooksFiltered && (
                      <ShowMore
                        pageOptions={notebookShowMore}
                        setPageOptions={setNotebookShowMore}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-9">
          {selectedNotebook ? (
            <>
              <div className="card">
                <h3 className="card-header">{selectedNotebook?.title}</h3>
                <div className="d-flex align-items-center m-3 px-3">
                  <label
                    className="text-muted me-2"
                    style={{ minWidth: "70px" }}
                  >
                    Filter By:
                  </label>
                  {/* Filter for Subjects */}
                  <select
                    className="form-select m-2"
                    onChange={handleSubjectChange}
                    value={selectedSubject}
                  >
                    <option value="all">All Subjects</option>
                    {allSubjects.map((subject) => (
                      <option key={subject._id} value={subject._id}>
                        {subject.title}
                      </option>
                    ))}
                  </select>

                  {/* Filter for Chapters */}
                  <select
                    className="form-select"
                    onChange={handleChapterChange}
                    value={selectedChapter}
                  >
                    <option value="all">All Chapters</option>
                    {allChapters.map((chapter) => (
                      <option key={chapter._id} value={chapter._id}>
                        {chapter.title}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="card-body">
                  <ol className="list-group list-group-numbered">
                    {selectedNotebookLoading && <Loader height="20vh" />}
                    {!selectedNotebookLoading &&
                    selectedNotebookData?.data?.length === 0 ? (
                      <>
                        <div className="d-flex justify-content-center align-items-center h-20">
                          <h5 className="mb-0 text-muted">
                            You have no questions in this notebook.
                          </h5>
                        </div>
                      </>
                    ) : (
                      <>
                        {selectedNotebookData?.data?.map((question, index) => (
                          <li className="list-group-item d-flex justify-content-between align-items-start cursor-pointer p-0 border-none">
                            <SingleLineQuestionView
                              questionIndex={index}
                              question={question}
                              selectedNotebook={selectedNotebook}
                              refresh={() => {
                                refetch();
                                refetchNotebook();
                              }}
                              onViewQuestion={viewQuestion}
                            />
                          </li>
                        ))}

                        {isFetchingQuestion && <Loader height="20vh" />}

                        <ShowMore
                          pageOptions={questionShowMore}
                          setPageOptions={setQuestionShowMore}
                        />
                      </>
                    )}
                  </ol>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <DeleteConfirmationModal
          show={showDeleteModal}
          toggleShow={toggleDeleteModal}
          onConfirm={confirmArchiveNotebook}
        />
      </div>
      <QuestionView
        show={showQuestion}
        hide={hideViewQuestion}
        question={selectedQuestion}
        notebookId={selectedNotebook?._id}
        refresh={() => {
          refetch();
          refetchNotebook();
        }}
        disableNext={currentIndex === selectedNotebookData?.data?.length - 1}
        disablePrev={currentIndex === 0}
        onNext={() => {
          viewQuestion(
            selectedNotebookData?.data[currentIndex + 1],
            currentIndex + 1
          );
        }}
        onPrev={() => {
          viewQuestion(
            selectedNotebookData?.data[currentIndex - 1],
            currentIndex - 1
          );
        }}
      />
    </>
  );
};

export default NoteBooks;
