import React from "react";
import { Modal } from "react-bootstrap";

const DeleteConfirmationModal = ({ show, toggleShow, onConfirm }) => {
  return (
    <Modal show={show} onHide={toggleShow}>
      <div className="modal-content">
        <div className="modal-card card">
          <div className="card-header">
            <h4 className="card-header-title">Confirm Deletion</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={toggleShow}
            ></button>
          </div>
          <div className="card-body">
            <p>Are you sure you want to delete this notebook? This action cannot be undone.</p>
          </div>
          <div className="card-footer">
            <div className="row">
              <div className="col">
                <button className="btn btn-secondary" onClick={toggleShow}>
                  Cancel
                </button>
              </div>
              <div className="col-auto">
                <button className="btn btn-danger" onClick={onConfirm}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;