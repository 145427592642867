import { Input, Radio, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import DropperDetailsFormV2 from "./DropperDetailsFormV2";
import PreparationDetailsV2ReasonModal from "./PreparationDetailsV2ReasonModal";
import apiClient from "../../../utils/apiClient";
import { setUser } from "../../../redux/slices/userSlice";

const YEAR = 2024; // update this year according to JEE Mains details

const STEPS = {
  online: {
    type: "checkbox",
    name: "onlineInstituteName",
    label: "Which online coaching are you enrolled in?",
    showNext: true,
    skipOption: false,
    options: [
      { value: "physics-wallah", label: "Physics Wallah (PW)" },
      { value: "unacademy", label: "Unacademy" },
      { value: "competishun", label: "Competishun" },
      { value: "IIT School", label: "IIT School" },
      { value: "esaral", label: "eSaral" },
      { value: "vedantu", label: "Vedantu" },
      { value: "Aakash Byju's", label: "Aakash Byju's" },
      { value: "Ahaguru", label: "Ahaguru" },
      { value: "other", label: "Other" },
    ],
    placeholder: "Which online institute are you enrolled in?",
    autoNext: false,
  },
  offline: {
    type: "select",
    name: "offlineInstituteName",
    label: "Which offline coaching are you enrolled in?",
    showNext: true,
    skipOption: false,
    options: [
      { value: "allen", label: "Allen" },
      { value: "FIITJEE", label: "FIITJEE" },
      { value: "CFAL", label: "CFAL" },
      { value: "Aakash Byju's", label: "Aakash Byju's" },
      { value: "Physics Walla Vidyapeeth", label: "Physics Walla Vidyapeeth" },
      { value: "Bakliwal Tutorials", label: "Bakliwal Tutorials" },
      { value: "Rahmani 30", label: "Rahmani 30" },
      { value: "Modi School", label: "Modi School" },
      { value: "Unacademy", label: "Unacademy" },
      { value: "Motion Academy", label: "Motion Academy" },
      { value: "VMC", label: "VMC" },
      { value: "other", label: "Other" },
    ],
    placeholder: "Which offline institute are you enrolled in?",
    autoNext: false,
  },
  freeOnlineBatch: {
    type: "select",
    name: "freeOnlineBatch",
    label: "Are you following any free online batch(es)?",
    showNext: true,
    skipOption: false,
    options: [
      {value: true, label: "Yes"},
      {value: false, label: "No"},
    ]
  },
  onlineBatchName: {
    type: "text",
    name: "onlineBatchName",
    label: "Enter the name of the online batch you're enrolled in",
    showNext: true,
    skipOption: true,
    placeholder: "Enter the name of the online batch you're enrolled in",
    autoNext: false,
  },
  offlineBatchName: {
    type: "text",
    name: "offlineBatchName",
    label: "Enter the name of the offline batch you're enrolled in",
    showNext: true,
    skipOption: true,
    placeholder: "Enter the name of the offline batch you're enrolled in",
    autoNext: false,
  },
  freeOnlineBatchName: {
    type: "text",
    name: "freeOnlineBatchName",
    label: "Enter the name(s) of the free online batch(es)",
    showNext: true,
    skipOption: true,
    placeholder: "Enter the name(s) of the free online batch(es)",
    autoNext: false,
  },
  batch: {
    type: "text",
    name: "batch",
    label: "Enter the name of the batch you're enrolled in",
    showNext: true,
    skipOption: false,
    placeholder: "Enter the name of the batch you're enrolled in",
    autoNext: false,
  },
  didUserAppearForJeeMain: {
    type: "select",
    name: "didUserAppearForJeeMain",
    label: `Did you appear for JEE Main ${YEAR}?`,
    showNext: true,
    skipOption: false,
    options: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
    placeholder: `Did you appear for JEE Main ${YEAR}?`,
    autoNext: false,
  },
};

const INITIAL_FORM = [
  {
    name: "preparationMode",
    label: "How are you preparing for JEE?",
    type: "select",
    options: [
      { value: "Online Paid Course", label: "Online Paid Course" },
      { value: "Offline Paid Course", label: "Offline Paid Course" },
      {
        value: "Combination of both Online & Offline Paid Courses",
        label: "Combination of both Online & Offline Paid Courses",
      },
      {
        value: "Self Study using Free Resources. i.e. YouTube, MARKS app",
        label: "Self Study using Free Resources. i.e. YouTube, MARKS app",
      },
    ],
    placeholder: "How are you preparing for JEE?",
    className: "w-100",
    size: "large",
    mode: "multiple",
    showNext: true,
    skipOption: false,
  },
];

const PreparationDetailsFormV2 = () => {
  const dispatch = useDispatch();
  const [user, setUserObject] = useState({});
  const { register, getValues, handleSubmit, watch, setValue } = useForm({
    mode: "all",
    values: {
      preparationMode: null,
    },
  });
  const [showDropperDetails, setShowDropperDetails] = useState(false);
  const [formCompleted, setFormCompleted] = useState(false);

  const watchPreparationMode = watch();

  useEffect(() => {
    const userString = localStorage.getItem("userProfile");
    if (userString) {
      setUserObject(JSON.parse(userString));
    }
  }, []);

  const [form, setForm] = useState(INITIAL_FORM);
  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = (name, value) => {
    setValue(name, value);
    setCurrentStep((prev) => prev + 1);
    if (currentStep === 0) {
      let newForm = [...INITIAL_FORM];
      if (value === "Online Paid Course") {
        newForm.push(STEPS.online, STEPS.onlineBatchName);
      } else if (value === "Offline Paid Course") {
        newForm.push(STEPS.offline, STEPS.offlineBatchName);
      } else if (
        value === "Combination of both Online & Offline Paid Courses"
      ) {
        newForm.push(
          STEPS.offline,
          STEPS.offlineBatchName,
          STEPS.online,
          STEPS.onlineBatchName,
        );
      } else if (
        value === "Self Study using Free Resources. i.e. YouTube, MARKS app"
      ) {
        newForm.push(STEPS.freeOnlineBatch);
      }
      setForm(newForm);
    } else if (currentStep === 1 && name === "freeOnlineBatch") {
      if (value === true) {
        setForm((prevForm) => [...prevForm, STEPS.freeOnlineBatchName]);
      } else {
        setForm((f) => [...f, STEPS.didUserAppearForJeeMain]);
      }
    } else if (currentStep === form.length - 1) {
      if (
        name === "onlineBatchName" ||
        name === "offlineBatchName" || 
        name === "freeOnlineBatchName" || 
        name === "freeOnlineBatch"
      ) {
        if (user?.class?.isDropper) {
          setForm((f) => [...f, STEPS.didUserAppearForJeeMain]);
        } else {
          return onSubmit();
        }
      } else {
        const formData = getValues();
        if (formData?.didUserAppearForJeeMain) {
          return setShowDropperDetails(true);
        } else {
          setShowDropperDetails(false);
          onSubmit();
        }
      }
    }
  };

  const handlePrev = () => {
    setCurrentStep((prev) => prev - 1);
    if (showDropperDetails) {
      setShowDropperDetails(false);
    } else {
      setForm((prev) => prev.slice(0, -1));
    }
  };

  const handleSkip = (name) => { 
    setCurrentStep((prev) => prev + 1);
    if (currentStep === form.length - 1) {
      if (
        name === "onlineBatchName" ||
        name === "offlineBatchName" || 
        name === "freeOnlineBatchName"
      ) {
        if (user?.class?.isDropper) {
          setForm((f) => [...f, STEPS.didUserAppearForJeeMain]);
        } else {
          return onSubmit();
        }
      }
    }
  };

  const onSubmit = handleSubmit((data) => {
    updateUserProfile(data);
  });

  const dropperSubmit = (data) => {
    setShowDropperDetails(false);
    updateUserProfile({
      ...watchPreparationMode,
      ...data,
    });
  };

  const updateUserProfile = async (updatedProfile) => {
    try {
      const response = await apiClient.patch(`/api/user/profile/`, {
        preparationDetailsV2: updatedProfile,
        year: YEAR,
      });
      localStorage.setItem(
        "userProfile",
        JSON.stringify(response.data.data?.user)
      );
      dispatch(setUser(response.data.data?.user));
      setFormCompleted(true);
    } catch (error) {
      setFormCompleted(false);
    }
  };
  return (
    <div>
      {/* {JSON.stringify(user, null, 2)} */}
      {!formCompleted ? (
        <>
          {showDropperDetails ? (
            <DropperDetailsFormV2
              setValue={setValue}
              year={YEAR}
              onSubmit={dropperSubmit}
              handlePrev={() => {}}
            />
          ) : (
            <FormQuestion
              {...form[currentStep]}
              handleNext={handleNext}
              handlePrev={handlePrev}
              handleSkip={handleSkip}
              key={currentStep}
              getValues={getValues}
              showPrev={currentStep === 1}
            />
          )}
        </>
      ) : (
        <div className="d-flex flex-column gap-3 align-items-center">
          <SuccessImage src="/icons/analysis/form-success.svg" alt="success" />
          <h2 className="mb-0">Thank you</h2>
          <p>Your response was recorded successfully.</p>
        </div>
      )}
    </div>
  );
};

export default PreparationDetailsFormV2;

const FormQuestion = ({
  label,
  options,
  name,
  canShowOther,
  showPrev,
  showNext,
  skipOption,
  autoNext,
  handleNext,
  handlePrev,
  handleSkip,
  placeholder,
  getValues,
  type,
}) => {
  const [value, setValue] = useState(null);
  const [othersValue, setOthersValue] = useState(null);
  const [customValue, setCustomValue] = useState(null);
  const [showExplanation, setShowExplanation] = useState(false);

  const handleRadioChange = (e) => {
    setValue(e.target.value);
    if (autoNext) {
      handleNext(name, e.target.value);
    }
  };

  const onSkip = () => {
    handleSkip(name);
  };

  const onNext = () => {
    let finalValue = value;
    if (type === "checkbox" && Array.isArray(value) && value.includes("other")) {
      finalValue = value.map(v => v === "other" ? customValue : v).filter(Boolean);
    } else if (value === "other") {
      finalValue = customValue || othersValue;
    }
    handleNext(name, finalValue);
  };

  const handleCheckboxChange = (checkedValues) => {
    if (!checkedValues.includes("other")) {
      setCustomValue(null);
    }
    setValue(checkedValues);
  };

  const handleCustomInputChange = (e) => {
    const newCustomValue = e.target.value;
    setCustomValue(newCustomValue);
  
    if (type === "checkbox" && Array.isArray(value) && value.includes("other")) {
      // Don't update the main value state here, just keep the custom value updated
      // The main value will be updated when the user clicks "Next"
    } else if (value === "other") {
      setOthersValue(newCustomValue);
    }
  };

  if (type === "text") {
    return (
      <div className="">
        <Label htmlFor={name}>{label}</Label>
        <Input
          placeholder={label}
          size="large"
          type="text"
          name={name}
          onChange={(e) => setValue(e.target.value)}
          id={name}
          className="form-control"
        />
        <ButtonContainer className="" showPrev={showPrev}>
          {showPrev && (
            <button className="btn btn-light" onClick={handlePrev}>
              Previous
            </button>
          )}
          {skipOption && (
            <button className="btn btn-light" onClick={onSkip}>
              Skip
            </button>
          )}
          {showNext && (
            <button
              onClick={onNext}
              className={`btn ${value && value.length ? "" : "disabled"}`}
            >
              Next
            </button>
          )}
        </ButtonContainer>
      </div>
    );
  }

  if (type === "checkbox") {
    return (
      <div className="">
        <Label htmlFor={name}>{label}</Label>
        <Checkbox.Group
          className="d-flex flex-column gap-2"
          size="large"
          options={options.map((option) => ({
            label: option.label,
            value: option.value,
          }))}
          onChange={handleCheckboxChange}
        />
        {value && value.includes("other") && (
          <div className="w-full form-group mt-2">
            <Input
              placeholder={placeholder}
              size="large"
              className="form-control"
              onChange={handleCustomInputChange}
              value={customValue}
            />
          </div>
        )}
        <ButtonContainer className="" showPrev={showPrev}>
          {showPrev && (
            <button className="btn btn-light" onClick={handlePrev}>
              Previous
            </button>
          )}
          {showNext && (
            <button
              onClick={onNext}
              className={`btn ${value && value.length ? "" : "disabled"}`}
            >
              Next
            </button>
          )}
        </ButtonContainer>
      </div>
    );   
  }

  return (
    <div className="">
      <div className="">
        <Label htmlFor="preparationMode">{label}</Label>
        {name === "preparationMode" && (
          <ExplanationText onClick={() => setShowExplanation(!showExplanation)}>
            Why are we asking for these details?
          </ExplanationText>
        )}
        {showExplanation && (
          <PreparationDetailsV2ReasonModal setShowExplanation={setShowExplanation} showExplanation={showExplanation} />
        )}
        <Radio.Group
          className="d-flex flex-column gap-2"
          size="large"
          onChange={handleRadioChange}
        >
          {options?.map((option, fieldIndex) => (
            <RadioBox
              key={fieldIndex}
              className=""
              checked={option.value === value}
              value={option.value}
            >
              {option.label}
            </RadioBox>
          ))}
        </Radio.Group>
      </div>

      {value === "other" && (
        <div className="w-full form-group mt-2">
          <Input
            placeholder={placeholder}
            size="large"
            className="form-control"
            onChange={(e) => setOthersValue(e.target.value)}
            value={othersValue}
          />
        </div>
      )}
      <ButtonContainer className="" showPrev={showPrev}>
        {showPrev && (
          <button className="btn btn-light" onClick={handlePrev}>
            Previous
          </button>
        )}
        {skipOption && (
          <button className="btn btn-light" onClick={onSkip}>
            Skip
          </button>
        )}
        {showNext && (
          <button
            onClick={onNext}
            className={`btn ${value != null ? "" : "disabled"}`}
          >
            Next
          </button>
        )}
      </ButtonContainer>
    </div>
  );
};

const ExplanationText = styled.p`
  color: #364fc7;
  font-size: 0.9rem;
  cursor: pointer;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  text-decoration: underline;

  &:hover {
    color: #2f3f9f;
  }
`;

const ExplanationBox = styled.div`
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: #495057;
`;

const SuccessImage = styled.img`
  width: 75px;
  height: 75px;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ showPrev }) => (showPrev ? "125px 1fr" : "1fr")};
  gap: 1rem;
  margin-top: 36px;

  button {
    background-color: #ccc;
    border: none;
    border-radius: 6px;
    padding: 0.6rem 1.2rem;
  }

  button:first-child {
    background-color: transparent;
    border: 2px solid ${({ theme }) => (theme.isDark ? "#1e3a5c" : "#f0f0f0")};

    &:hover {
      background-color: #f0f0f0;
    }
  }

  button:last-child {
    background-color: #364fc7;
    color: #fff;

    &:hover {
      background-color: #2f3f9f;
    }
  }
`;

export const ButtonGrid = ButtonContainer;

const Label = styled.label`
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;

const RadioBox = styled(Radio)`
  display: flex;
  align-items: center;
  width: 100%;

  ${({ checked, theme }) =>
    checked
      ? `
    border: 2px solid #364fc7;
    color: #364fc7;
  `
      : `
    border: 2px solid ${theme.isDark ? "#1e3a5c" : "#f0f0f0"};
    color: ${theme.isDark ? "#f0f0f0" : "#1e3a5c"};
  `}
  border-radius: 6px;
  padding: 8px 16px;
  margin-bottom: 0.5rem;
  input[type="radio"] {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: ${({ theme }) => (theme.isDark ? "#1e3a5c" : "#f0f0f0")};
  }
`;
