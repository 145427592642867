import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { ThemeContext } from '../../context/ThemeStore';

const Meta = ({ title }) => {
    const { siteTitle, isDarkTheme } = useContext(ThemeContext)
    return (
        <div>
            <Helmet>
                <title>{title} - {siteTitle}</title>
                <meta
                    name="description"
                    content="Online JEE Main, JEE Advanced, BITSAT, WBJEE, MHT CET and other competitive exams preparation platform"
                />
                <link rel="stylesheet" href="/assets/css/app.css" />
                <link rel="stylesheet" href="/assets/css/theme.bundle.css" id="lightTheme" />
                {isDarkTheme ? (
                    <link
                        rel="stylesheet"
                        href="/assets/css/theme-dark.bundle.css"
                        id="darkTheme"
                    />
                ) : ''}
            </Helmet>
        </div>
    );
}

export default Meta;
