import React, { useState, useEffect } from "react";
import NotesModal from "./NotesModal";

const TakeNotes = ({ notes, questionId }) => {
  const [noteExists, setNoteExists] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setNoteExists(!!notes);
  }, [notes, questionId]);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <>
      <div
        className={`d-flex align-items-center gap-2 cursor-pointer ${
          noteExists ? "text-primary" : "text-primary"
        }`}
        onClick={handleShowModal}
      >
        <span
          className="fe fe-message-square position-relative"
          style={{
            fontSize: "14px",
          }}
        >
          {noteExists && (
            <span
              className="position-absolute text-danger"
              style={{
                top: "-14px",
                right: "-1px",
                fontSize: "25px",
              }}
            >
              •
            </span>
          )}
        </span>
        <h5 className="mb-0 text-nowrap">Notes</h5>
      </div>
      <NotesModal
        show={showModal}
        handleClose={handleCloseModal}
        notes={notes}
        questionId={questionId}
        handleNoteExists={setNoteExists}
      />
    </>
  );
};

export default TakeNotes;
