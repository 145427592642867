import React from "react";
import { Link } from "react-router-dom";

const NoConnection = () => {
  return (
    <div
      style={{ minHeight: "100vh" }}
      className="d-flex flex-row align-items-center"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <span className="display-1 d-block">
              Internet Connection Unstable
            </span>
            <br />
            <div className="mt-4 mb-4 lead">
              Please check your internet connection and click on the button
              below to return to the home page.
            </div>

            <div className="mb-6">
              If you were giving a test, please know that all your data is
              synced up and you can continue the test where you left off.
            </div>
            <Link to="/" className="btn btn-primary">
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const Page404 = () => {
  return (
    <div
      style={{ minHeight: "100vh" }}
      className="d-flex flex-row align-items-center"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <span className="display-1 d-block">404 Not Found</span>
            <br />
            <div className="mb-4 lead">
              The page you are looking for was not found.
            </div>
            <Link to="/" className="btn btn-primary">
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const Page503 = ({ resetErrorBoundary }) => {
  return (
    <div
      style={{ minHeight: "100vh" }}
      className="d-flex flex-row align-items-center"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <span className="display-1 d-block">503 Service Unavailable</span>
            <br />
            <div className="mb-4 lead">
              An error occured while loading the page.
            </div>

            <button className="btn btn-primary" onClick={resetErrorBoundary}>
              Try again
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ErrorPages = ({ type, resetErrorBoundary = null }) => {
  switch (type) {
    case 404:
      return <Page404 />;
    case 503:
      return <Page503 resetErrorBoundary={resetErrorBoundary} />;
    case 504:
      return <NoConnection />;
  }
};

export default ErrorPages;
