exports.objectToQueryParams = (objectQuery) => {
  let str = [];
  for (let p in objectQuery) {
    if (
      objectQuery.hasOwnProperty(p) &&
      objectQuery[p]?.toString().length > 0
    ) {
      str.push(
        encodeURIComponent(p) + "=" + encodeURIComponent(objectQuery[p])
      );
    }
  }

  return str.join("&");
};
