import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";

const HelloBar = () => {
  const [alert, setAlert] = useState(null);
  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    // Check if the hellobar has been closed for this session
    const isClosed = sessionStorage.getItem("hellobarClosed");
    if (isClosed) return; // Do not fetch or show the hellobar if closed

    axios.get("/api/hellobar").then((res) => {
      setAlert(res.data?.hellobar);
    });
  }, []);

  useEffect(() => {
    if (alert && alert.isTimerEnabled) {
      const timer = setInterval(() => {
        const now = new Date().getTime();
        const targetDate = new Date(alert.countdownDate).getTime();
        const difference = targetDate - now;

        if (difference > 0) {
          const days = Math.floor(difference / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (difference % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((difference % (1000 * 60)) / 1000);

          setTimeLeft({ days, hours, minutes, seconds });
        } else {
          clearInterval(timer);
          setTimeLeft(null);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [alert]);

  const handleClose = () => {
    sessionStorage.setItem("hellobarClosed", "true"); // Mark the hellobar as closed for this session
    setAlert(null);
  };

  if (!alert || !alert.isVisible) return null;

  const renderCountdown = () => {
    if (!timeLeft) {
      return <span>{alert.messageAfterCountDown}</span>;
    }
    return (
      <>
        <span>{alert.message}</span>{" "}
        <TimerText>
          {timeLeft.days} days {timeLeft.hours} hrs {timeLeft.minutes} min{" "}
          {timeLeft.seconds} sec
        </TimerText>
      </>
    );
  };

  return (
    <AlertBar>
      {alert.showCloseButton && (
        <CloseButton onClick={handleClose}>×</CloseButton>
      )}
      {alert.isTimerEnabled ? renderCountdown() : alert.message}
      {alert.isButtonEnabled && (
        <HelloButton href={alert.url} target="_blank">{alert.buttonText}</HelloButton>
      )}
    </AlertBar>
  );
};

const AlertBar = styled.div`
  background-color: #ff4b4b;
  color: white;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  position: sticky;
  top: 0;
  z-index: 9999;
  width: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const TimerText = styled.span`
  color: yellow;
  margin-left: 0.5rem;
`;

const HelloButton = styled.a`
  background-color: white;
  color: #ff4b4b !important;
  border: none;
  border-radius: 8px;
  font-size: 14.4px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  line-height: 1.2;
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin-left: 1rem;

  &:hover {
    opacity: 0.9;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;

  &:hover {
    color: #ddd;
  }
`;

export default HelloBar;
