import { combineReducers } from "redux";

// import reducers here
import testReducer from "./slices/testSlice";
import packReducer from "./slices/packSlice";
import resoReducer from "./slices/resoSlice";
import userReducer from "./slices/userSlice";
import chapterTrackReducer from "./slices/chapterTrackSlice";
import analysisV2Slice from "./slices/analysisV2Slice";
import timerReducer from "./slices/timerSlice";
import globalReducer from "./slices/globalSlice";


// combining reducers for the store
const reducers = combineReducers({
  test: testReducer,
  pack: packReducer,
  reso: resoReducer,
  user: userReducer,
  chapterTrack: chapterTrackReducer,
  analysisOverview: analysisV2Slice,
  timer: timerReducer,
  global: globalReducer,
});

export default reducers;
