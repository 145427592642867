import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import Question from "./Question";
import SingleBookmark from "./SingleBookmark";
import QUESTION_TYPE_MAP from "../../utils/variables";
import { ChevronLeft, ChevronRight, X } from "lucide-react";

const QuestionView = ({
    show,
    hide,
    question,
    notebookId,
    refresh,
    onNext,
    onPrev,
    disableNext,
    disablePrev,
}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const modalBodyRef = useRef(null);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getButtonPosition = () => {
        if (windowWidth > 991) return '-100px';
        if (windowWidth > 767) return '-50px';
        if (windowWidth > 575) return '0px';
        return '0px';
    };

    const buttonStyle = {
        width: "40px",
        height: "40px",
        backgroundColor: 'var(--nav-custom)',
        border: 'none',
        transition: 'all 0.3s ease',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
    };

    const scrollToTop = () => {
        if (modalBodyRef.current) {
            modalBodyRef.current.scrollTop = 0;
        }
    };

    const handlePrevQuestion = () => {
        onPrev();
        scrollToTop();
    };

    const handleNextQuestion = () => {
        onNext();
        scrollToTop();
    };

    return (
        <>
            <Modal
                show={show}
                onHide={hide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="modal-100w position-relative"
            >
                <Modal.Header className="d-flex justify-content-between">
                    <div className="">
                        <Modal.Title
                            id="contained-modal-title-vcenter"
                            className="mb-0"
                        >
                            Question View
                        </Modal.Title>
                        <div className="row">
                            <div className="col-12">
                                <p className="text-muted mb-0">
                                    {QUESTION_TYPE_MAP[question?.questionId?.type]}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center gap-3">
                        <SingleBookmark
                            questionId={question?.questionId?._id}
                            notebookId={notebookId}
                            refresh={() => {
                                hide();
                                refresh();
                            }}
                        />
                       <X style={{cursor:'pointer'}} onClick={hide}/>
                    </div>
                </Modal.Header>

                <Modal.Body ref={modalBodyRef} style={{ maxHeight: 'calc(100vh - 150px)', overflowY: 'auto', position: 'relative' }}>
                    <Question question={question} />
                </Modal.Body>

                <button 
                    className="btn d-flex justify-content-center p-0 align-items-center rounded-circle"
                    onClick={handlePrevQuestion} 
                    disabled={disablePrev}
                    style={{
                        ...buttonStyle,
                        left: getButtonPosition(),
                    }}
                >
                    <ChevronLeft size='24' color='var(--icon)'/>
                </button>
                <button 
                    className="btn d-flex justify-content-center p-0 align-items-center rounded-circle"
                    onClick={handleNextQuestion} 
                    disabled={disableNext}
                    style={{
                        ...buttonStyle,
                        right: getButtonPosition(),
                    }}
                >
                    <ChevronRight size='24' color='var(--icon)'/>
                </button>
            </Modal>
        </>
    );
};

export default QuestionView;