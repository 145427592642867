import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import Dashboard from "../layout/dashboard";

const Index = () => {
  const router = useHistory();
  const [cookies] = useCookies();

  useEffect(() => {
    if (cookies["token"]) {
      router.push("/v2/home");
    } else {
      router.push("/auth");
    }
  }, []);

  return <Dashboard />;
};

export default Index;
