const QUESTION_TYPE_MAP = {
  numerical: "Numerical",
  singleCorrect: "Single Correct",
  multipleCorrect: "Multiple Correct",
  fillTheBlanks: "Fill the Blanks",
};

const ANALYSIS_TABS = [
  {
    name: "Performance",
    value: "performance",
    link: "performance",
  },
  {
    name: "Timeline",
    value: "overview",
    link: "timeline",
  },
  {
    name: "Qs Type Breakup",
    value: "time-accuracy",
    link: "qs-type-breakup",
  },
  {
    name: "Quality of Attempt",
    value: "quality",
    link: "quality-of-attempt",
  },
  {
    name: "Quality of Time Spent",
    value: "difficulty",
    link: "quality-of-time-spent",
  },
  {
    name: "Subject-Wise Time",
    value: "subject-wise-time",
    link: "subject-wise-time",
  },
  {
    name: "Hour-wise Breakdown",
    value: "hour-wise-breakdown",
    link: "hour-wise-breakdown",
  },
  // {
  //   name: "Last one hour",
  //   value: "errors",
  //   link: "last-one-hour",
  // },
  {
    name: "Difficulty Analysis",
    value: "subject-movement",
    link: "difficulty-analysis",
  },
  {
    name: "Unit Analysis",
    value: "all-questions",
    link: "unit-analysis",
  },
  {
    name: "Chapter & Topic Analysis",
    value: "question-journey",
    link: "chapter-topic-analysis",
  },
];

export { ANALYSIS_TABS };

export default QUESTION_TYPE_MAP;
