import React, { useEffect, useState } from "react";

const Modal = ({ children, show, title, onClose }) => {
  const [iframeShow, setIframeShow] = useState(true);
  
  const handleClose = () => {
    const modalElement = document.getElementById("selectPackModal");
    modalElement.classList.remove("show");
    modalElement.classList.add("hidden");
    setIframeShow(false);
    onClose();
  };

  useEffect(() => {
    if (show) {
      setIframeShow(true);
    }
  }, [show]);

  return (
    <div className={`modal ${show ? 'show' : 'hidden'}`} id="selectPackModal" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered iframe-dialog" role="document">
        <div className="modal-content iframe-content">
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
            <button type="button" className="close" onClick={handleClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body iframe-body">{iframeShow ? children : null}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
