import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useCookies } from "react-cookie";
import { Modal } from "react-bootstrap";

const userProfile = JSON.parse(localStorage.getItem("userProfile"));

const PackCardPaid = (props) => {
    const [cookies] = useCookies();
    const [notified, setNotified] = useState(false);
    let history = useHistory();
    const [showPackSelection, setShowPackSelection] = useState(false);

    useEffect(() => {
        setNotified(props?.requested);
    }, [props]);

    const handleScheduleClick = () => {
        window.open(props?.schedulePdf, "_blank");
    };

    const handleNotifyMeClick = async () => {
        if (props?.requested) return;
        const res = await fetch(
            `${process.env.REACT_APP_API_URL}/api/packs/notify-me`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${cookies["token"]}`,
                },
                body: JSON.stringify({
                    name: userProfile?.name,
                    email: userProfile?.email,
                    mobile: userProfile?.mobileNumber,
                    packId: props?._id,
                    packTitle: props?.title,
                }),
            }
        );
        const data = await res.json();
        if (data?.success) {
            setNotified(true);
            localStorage.setItem(`notifyMe-${props?._id}`, true);
        }
    };

    const handleBuyNowClick = () => {
        const paymentLink = new URL(props?.paymentLink);
        paymentLink.searchParams.append("name", userProfile?.name);
        paymentLink.searchParams.append("email", userProfile?.email);
        paymentLink.searchParams.set("utm_medium", "dashboard_platform");
        window.open(paymentLink, "_blank");
    };

    const handleViewTestSeriesClick = () => {
        if (props.secondaryPacks && props.secondaryPacks.length > 0) {
            togglePackSelection();
        } else {
            props.handleViewPack(props?._id);
        }
    };

    const togglePackSelection = () => {
        setShowPackSelection(!showPackSelection);
    };

    return (
        <>
            <div
                className={`card w-100 text-whit ${
                    props.highlightMe
                        ? "border-danger border-2 bg-dark-1"
                        : "bg-dark-1"
                }`}
            >
                <div
                    className="card-header p-0 border-none pb-3"
                    style={{
                        height: "auto",
                    }}
                >
                    {props.thumbnail ? (
                        <CardThumbnail
                            src={props.thumbnail}
                            alt={props?.title}
                        />
                    ) : (
                        <div className="d-flex align-items-center gap-3 p-4 pb-3">
                            <img
                                width="30"
                                height="30"
                                src={props?.icon}
                                alt={props?.title}
                            />
                            <h2 className="mb-0" style={{ fontSize: "16px" }}>
                                {props?.primaryTitle || props?.title}
                            </h2>
                        </div>
                    )}
                </div>
                <div className="card-body py-0">
                    {props.schedulePdf && props.schedulePdf.length > 0 && (
                        <div
                            className="d-flex gap-3 cursor-pointer mb-3"
                            onClick={handleScheduleClick}
                            style={{ fontSize: "13px" }}
                        >
                            <span className="fe fe-file-text"></span>
                            <p className="mb-0">Download Schedule</p>
                        </div>
                    )}
                    <DescriptionComponent {...props} />
                </div>

                <div className="card-footer mx-4 px-0 border-none">
                    <div className="d-flex justify-content-between flex-row-reverse">
                        {props?.isComingSoon ? (
                            <>
                                <button
                                    className="btn btn-outline-danger"
                                    onClick={handleNotifyMeClick}
                                >
                                    {notified ? (
                                        <>
                                            <span className="fe fe-check"></span>{" "}
                                            You'll be notified
                                        </>
                                    ) : (
                                        <>
                                            <span className="fe fe-bell"></span>{" "}
                                            Notify me
                                        </>
                                    )}
                                </button>
                            </>
                        ) : (
                            <>
                                {props?.purchased ? (
                                    <>
                                        {/* <button
                                            className="btn btn-success btn-sm"
                                            // onClick={handleBuyNowClick}
                                        >
                                            Access Now
                                        </button> */}

                                        <button
                                            className="btn btn-success btn-sm"
                                            onClick={handleViewTestSeriesClick}
                                        >
                                            {props.secondaryPacks &&
                                            props.secondaryPacks.length > 0
                                                ? "Access Tests"
                                                : "Access Test"}
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={handleBuyNowClick}
                                        >
                                            Buy Now
                                        </button>

                                        <button
                                            className="btn btn-link btn-sm"
                                            onClick={handleViewTestSeriesClick}
                                        >
                                            {props.secondaryPacks &&
                                            props.secondaryPacks.length > 0
                                                ? "View Packs"
                                                : "View Tests"}
                                        </button>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
                {props.highlightMe && props.highlightMeText && (
                    <CardTag>{props.highlightMeText}</CardTag>
                )}
            </div>

            <Modal
                show={showPackSelection}
                onHide={togglePackSelection}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div class="card-header">
                    <h4 class="card-header-title">{`Select a pack:`}</h4>
                    <button
                        type="button"
                        class="btn-close"
                        onClick={togglePackSelection}
                        aria-label="Close"
                    ></button>
                </div>
                <div className="card-body">
                    <ul className="list-group list-group-flush list my-n3">
                        <PackListItem pack={props} />
                        {props?.secondaryPacks?.map((pack, index) => (
                            <PackListItem pack={pack} />
                        ))}
                    </ul>
                </div>
            </Modal>
        </>
    );
};

export default PackCardPaid;

const PackListItem = ({ pack }) => {
    let history = useHistory();

    const goToPack = () => {
        if (pack?.version === 3) {
            return history.push(`/packs/${pack?._id}`);
        }
        history.push(`/v2/dash?packId=${pack?._id}`);
    };
    return (
        <li className="list-group-item" key={pack?._id}>
            <div className="row align-items-center">
                <div className="col">
                    <h4 className="mb-1 name pointer d-flex align-items-center gap-3">
                        <img
                            src={pack?.icon}
                            alt={pack.title}
                            width={30}
                            height={30}
                        />
                        <span onClick={goToPack}>{pack.title}</span>
                    </h4>
                    {/* <p className="small mb-0 text-muted">
            Total tests: {pack?.tests?.length}
          </p> */}
                </div>
                <div className="col-auto">
                    <button className="btn btn-sm btn-white" onClick={goToPack}>
                        View Tests
                    </button>
                </div>
            </div>
        </li>
    );
};

const DescriptionComponent = (props) => {
    const [showAllItems, setShowAllItems] = useState(false);
    const displayCount = showAllItems ? props.descriptionPoints.length : 3;

    return (
        <div>
            {props.descriptionPoints
                ?.slice(0, displayCount)
                .map((point, index) => (
                    <div
                        className="d-flex gap-3 mb-2"
                        key={index}
                        style={{ fontSize: "13px" }}
                    >
                        <span className="fe fe-check"></span>
                        <p className="mb-0">{point}</p>
                    </div>
                ))}

            {/* {props.descriptionPoints?.length > 3 && (
        <button
          onClick={() => setShowAllItems(!showAllItems)}
          className="btn text-primary"
        >
          {showAllItems ? "- View Less" : `+ View More`}
        </button>
      )} */}
        </div>
    );
};

const CardThumbnail = styled.img`
    width: 100%;
    height: 100%;
    min-height: 100px;
    object-fit: cover;
    object-position: center;
    border-radius: 0.4rem 0.4rem 0px 0px;
    // box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
`;

const CardTag = styled.div`
    position: absolute;
    top: -15px;
    right: 20px;
    display: inline-flex;
    padding: 3px 12px 3px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    background: var(--bs-danger);
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
`;
