import React from 'react';
import TimeLine from '../../components/dashboard/tests/TimeLine';
import Meta from '../../components/global/Meta';
import Dashboard from '../../layout/dashboard';

const Timeline = () => {
    return (
        <Dashboard>
            <Meta title={`Tests Timeline`} />

            <TimeLine />
        </Dashboard>
    );
}

export default Timeline;
