import { createSlice, current } from "@reduxjs/toolkit";
import { toInteger } from "lodash";
import { requestQueue } from "../../lib/requestQueue";
import Axios from "axios";
import ApiQueue from "../../lib/ApiQueue";

const API_URL = process.env.REACT_APP_API_URL;

export const testSlice = createSlice({
  name: "test",
  initialState: {
    status: "main", // main, bonus, submissionMain, submissionBonus
    timeLeft: 0,
    testDetails: {},
    sessionDetails: {},
    currentQuestion: {},
    currentQuestionIndex: 0,
    currentSection: {},
    currentSubject: "",
    sections: [],
    bonusSections: [],
    ftbAnswers: [],
    testPaused: false,
  },
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setTimer: (state, action) => {
      state.timeLeft = action.payload;
    },
    decrementTime: (state, action) => {
      state.timeLeft -= action.payload;
    },
    setTestDetails: (state, action) => {
      state.testDetails = action.payload;
    },
    setSessionDetails: (state, action) => {
      state.sessionDetails = action.payload;
    },
    setCurrentSection: (state, action) => {
      state.currentSection = action.payload;
      state.currentQuestion = action.payload.questions[0];
    },
    setCurrentQuestion: (state, action) => {
      state.currentQuestion = action.payload;
    },
    setCurrentQuestionIndex: (state, action) => {
      state.currentQuestionIndex = action.payload;
    },
    setCurrentSubject: (state, action) => {
      state.currentSubject = action.payload;
    },
    setSections: (state, action) => {
      state.sections = action.payload;
    },
    setBonusSections: (state, action) => {
      state.bonusSections = action.payload;
    },
    editQuestionState: (state, action) => {
      const { shift, updateCurrent } = action.payload;
      const { sectionIndex, questionIndex } = action.payload;
      const original = state.sections[sectionIndex].questions[questionIndex];
      const newState = {
        ...original,
        ...shift,
        timeLeftWhenAttempted: toInteger(state.timeLeft / 60),
        timeTaken: shift.timeTaken
          ? (original?.timeTaken || 0) + shift.timeTaken
          : original?.timeTaken || 0,
      };
      state.sections[sectionIndex].questions[questionIndex] = newState;
      state.currentSection = state.sections[sectionIndex];
      if (updateCurrent)
        state.currentQuestion =
          state.sections[sectionIndex].questions[questionIndex];
    },
    switchToBonus: (state, action) => {
      const sections = state.sections;
      const bonusSections = state.bonusSections;
      state.currentSection = bonusSections[0];
      state.currentQuestion = bonusSections[0].questions[0];
      state.sections = bonusSections;
      state.bonusSections = sections;
      state.status = "bonus";
    },
    setFTBAnswers: (state, action) => {
      const { questionId, qIndex, answer } = action.payload;
      const newFTBAnswers = [...state.ftbAnswers];
      const localQuestionIndex = newFTBAnswers.findIndex(
        (question) => question.questionId === questionId
      );
      if (localQuestionIndex === -1) {
        newFTBAnswers.push({ questionId, qIndex, answer });
      } else {
        newFTBAnswers[localQuestionIndex].answer = answer;
      }
      state.ftbAnswers = newFTBAnswers;
      console.log("State FTB Answers: ", current(state));
    },
    resetFTBAnswers: (state, action) => {
      state.ftbAnswers = [];
    },
    setTestPaused: (state, action) => {
      state.testPaused = action.payload;
    },
  },
});

export const {
  setStatus,
  setTimer,
  decrementTime,
  setTestDetails,
  setSessionDetails,
  setCurrentSection,
  setCurrentQuestion,
  setCurrentQuestionIndex,
  setCurrentSubject,
  setSections,
  setBonusSections,
  editQuestionState,
  switchToBonus,
  setFTBAnswers,
  resetFTBAnswers,
  setTestPaused,
} = testSlice.actions;

const apiQueue = new ApiQueue();

export const saveAnswer = (token, payload) => async (dispatch, getState) => {
  try {
    const SI = payload.sectionIndex;
    const QI = payload.questionIndex;
    const attemptNumber = payload.attemptNumber;
    dispatch(editQuestionState(payload)); // update state locally
    let currentQuestion = getState().test.sections[SI].questions[QI]; // get updated state
    let eventTrackingEnabled =
      getState().analysisOverview?.eventTrackingEnabled;
    let questionData = { ...currentQuestion };
    if (payload?.questionId) questionData.questionId = payload.questionId;

    let URL = `${API_URL}/api/test/tempTestDetails/${currentQuestion.testId}`;
    let authToken = token;
    if (!authToken) {
      authToken = sessionStorage.getItem("token");
    }
    if (
      eventTrackingEnabled &&
      eventTrackingEnabled?.data?.isEventTrackingEnabled &&
      !attemptNumber
    ) {
      URL = `${API_URL}/api/test/v2/tempTestDetails/${currentQuestion.testId}`;
    }
    if (attemptNumber) {
      URL += `?attemptNumber=${attemptNumber}`;
    }
    apiQueue.enQueue(async () =>
      Axios.post(URL, questionData, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
    );
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const saveSubject =
  (token, subjectId, force = false, attemptNumber = null) =>
  async (dispatch, getState) => {
    try {
      const subjectNotMatch = getState().test.currentSubject !== subjectId;
      if (force || subjectNotMatch) {
        dispatch(setCurrentSubject(subjectId));
        const testId = getState().test.testDetails._id;
        let eventTrackingEnabled =
          getState().analysisOverview?.eventTrackingEnabled;
        let URL = `${API_URL}/api/test/tempTestDetails/${testId}`;
        if (
          eventTrackingEnabled &&
          eventTrackingEnabled?.data?.isEventTrackingEnabled &&
          !attemptNumber
        ) {
          URL = `${API_URL}/api/test/v2/tempTestDetails/${testId}`;
        }

        if (attemptNumber) {
          URL += `?attemptNumber=${attemptNumber}`;
        }

        let authToken = token;
        if (!authToken) {
          authToken = sessionStorage.getItem("token");
        }
        apiQueue.enQueue(async () =>
          Axios.post(
            URL,
            {
              testId,
              type: "subjectMovement",
              movement: { subject: subjectId },
            },
            { headers: { Authorization: `Bearer ${authToken}` } }
          )
        );
      }
      return null;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

export const saveTimer = (token, attemptNumber = null) => async (dispatch, getState) => {
  try {
    const testId = getState().test.testDetails._id;
    let authToken = token;
    if (!authToken) {
      authToken = sessionStorage.getItem("token");
    }
    apiQueue.enQueue(async () =>
      Axios.patch(`${API_URL}/api/test/timeUpdation/${testId}?attemptNumber=${attemptNumber}`, null, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
    );
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const saveTimerV2 = (token) => async (dispatch, getState) => {
  try {
    const testId = getState().test.testDetails._id;
    const timeLeft = getState().test.timeLeft;
    let authToken = token;
    if (!authToken) {
      authToken = sessionStorage.getItem("token");
    }
    apiQueue.enQueue(async () =>
      Axios.patch(
        `${API_URL}/api/test/v2/timeUpdation/${testId}`,
        {
          timeLeftInSeconds: timeLeft,
          currentTimeStampUtc: new Date().toISOString(),
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      )
    );
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const saveBonus = (token) => async (dispatch, getState) => {
  try {
    const testId = getState().test.testDetails._id;
    let authToken = token;
    if (!authToken) {
      authToken = sessionStorage.getItem("token");
    }
    const res = await Axios.patch(
      `${API_URL}/api/test/bonusAttempt/${testId}`,
      { bonusAttempt: true },
      { headers: { Authorization: `Bearer ${authToken}` } }
    );
    if (res.status === 200) dispatch(switchToBonus());
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const submitTest =
  (token, pack, router, attemptNumber = null) => async (dispatch, getState) => {
    try {
      const testId = getState().test.testDetails._id;
      let authToken = token;
      if (!authToken) {
        authToken = sessionStorage.getItem("token");
      }
      const res = await Axios.get(
        `${API_URL}/api/test/analysis/${pack}/${testId}?attemptNumber=${attemptNumber}`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      if (res.status === 200){
        try {
          await Axios.get(
            `${API_URL}/api/test/detailedAnalysis/${testId}?pack=${pack}&attemptNumber=${attemptNumber}`
          );
          
        } catch (err) {
          console.error("Error fetching data:", err);
        } 
      }
        router.push(`/tests/${pack}/${testId}/submission`);
      return res;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

export default testSlice.reducer;
