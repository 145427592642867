import { createSlice } from "@reduxjs/toolkit";
import apiClient from "../../utils/apiClient";
import { ENDPOINTS } from "../../utils/strings";

const createInitialStateEntry = () => ({
  data: null,
  loading: false,
  error: null,
});

const initialState = {
  test: createInitialStateEntry(),
  overview: createInitialStateEntry(),
  detailed: createInitialStateEntry(),
  timeAccuracy: createInitialStateEntry(),
  qualityOfAttempt: createInitialStateEntry(),
  difficultyAnalysis: createInitialStateEntry(),
  errorAnalysis: createInitialStateEntry(),
  errorOptimization: createInitialStateEntry(),
  subjectMovement: createInitialStateEntry(),
  timeJourney: createInitialStateEntry(),
  allQuestionsTable: createInitialStateEntry(),
  allQuestionsAnalysis: createInitialStateEntry(),
  question: createInitialStateEntry(),
  questionJourney: createInitialStateEntry(),
  v2Status: createInitialStateEntry(),
  eventTrackingEnabled: createInitialStateEntry(),
};

const createAsyncAction = (name, stateKey) => {
  return {
    [`fetch${name}`]: (state) => {
      state[stateKey].loading = true;
    },
    [`fetch${name}Success`]: (state, action) => {
      state[stateKey].data = action.payload.data;
      state[stateKey].loading = false;
      state[stateKey].error = null;
    },
    [`fetch${name}Failure`]: (state, action) => {
      state[stateKey].data = null;
      state[stateKey].loading = false;
      state[stateKey].error = action.payload;
    },
  };
};

export const analysisV2Slice = createSlice({
  name: "analysisV2",
  initialState,
  reducers: {
    ...createAsyncAction("Overview", "overview"),
    ...createAsyncAction("Detailed", "detailed"),
    ...createAsyncAction("TimeAccuracy", "timeAccuracy"),
    ...createAsyncAction("QualityOfAttempt", "qualityOfAttempt"),
    ...createAsyncAction("DifficultyAnalysis", "difficultyAnalysis"),
    ...createAsyncAction("ErrorAnalysis", "errorAnalysis"),
    ...createAsyncAction("SubjectMovement", "subjectMovement"),
    ...createAsyncAction("Test", "test"),
    ...createAsyncAction("Journey", "timeJourney"),
    ...createAsyncAction("AllQuestionsTable", "allQuestionsTable"),
    ...createAsyncAction("AllQuestionsAnalysis", "allQuestionsAnalysis"),
    ...createAsyncAction("Question", "question"),
    ...createAsyncAction("QuestionJourney", "questionJourney"),
    ...createAsyncAction("V2Status", "v2Status"),
    ...createAsyncAction("EventTrackingEnabled", "eventTrackingEnabled"),
    ...createAsyncAction("ErrorOptimization", "errorOptimization"),

    fetchTest: (state) => {
      state.test.loading = true;
    },
    fetchTestSuccess: (state, action) => {
      state.test.data = action.payload;
      state.test.loading = false;
      state.test.error = null;
    },
    fetchTestFailure: (state, action) => {
      state.test.data = null;
      state.test.loading = false;
      state.test.error = action.payload;
    },
  },
});

const createAnalysisAction =
  (actionType, endpointPart) =>
  ({ packId, testId }) =>
  async (dispatch) => {
    dispatch(analysisV2Slice.actions[actionType]());
    try {
      const response = await apiClient.get(
        ENDPOINTS.ANALYSISV3.OVERVIEW + `/${packId}/${testId}/${endpointPart}`
      );
      dispatch(analysisV2Slice.actions[`${actionType}Success`](response.data));
    } catch (error) {
      dispatch(analysisV2Slice.actions[`${actionType}Failure`](error));
    }
  };

export const getAnalysisOverview = createAnalysisAction(
  "fetchOverview",
  "overview"
);
export const getAnalysisDetailed = createAnalysisAction(
  "fetchDetailed",
  "detailed"
);
export const getAnalysisTimeAccuracy = createAnalysisAction(
  "fetchTimeAccuracy",
  "timeaccuracy"
);
export const getAnalysisQualityOfAttempt = createAnalysisAction(
  "fetchQualityOfAttempt",
  "qualityattempt"
);
export const getDifficultyAnalysis = createAnalysisAction(
  "fetchDifficultyAnalysis",
  "difficultyAnalysis"
);
export const getErrorAnalysis = createAnalysisAction(
  "fetchErrorAnalysis",
  "error"
);
export const getSubjectMovement = createAnalysisAction(
  "fetchSubjectMovement",
  "subjectmovement"
);
export const getJourney = createAnalysisAction("fetchJourney", "timejourney");
export const getAllQuestionsTable = createAnalysisAction(
  "fetchAllQuestionsTable",
  "allquestiondetailed"
);
export const getAllQuestionsAnalysis = createAnalysisAction(
  "fetchAllQuestionsAnalysis",
  "allquestion"
);
export const getQuestionJourney = createAnalysisAction(
  "fetchQuestionJourney",
  "questionjourney"
);
export const getV2Status = createAnalysisAction("fetchV2Status", "isEnabled");
export const getEventTrackingEnabled = createAnalysisAction(
  "fetchEventTrackingEnabled",
  "isEventTrackingEnabled"
);
export const getErrorOptimization = createAnalysisAction(
  "fetchErrorOptimization",
  "errorOptimization"
);

export const fetchTest =
  ({ packId, testId }) =>
  async (dispatch) => {
    dispatch(analysisV2Slice.actions.fetchTest());
    try {
      const response = await apiClient.get(
        ENDPOINTS.TEST + `/${packId}/${testId}`
      );
      dispatch(analysisV2Slice.actions.fetchTestSuccess(response.data));
    } catch (error) {
      dispatch(analysisV2Slice.actions.fetchTestFailure(error));
    }
  };

export const fetchQuestion =
  ({ packId, testId, questionId }) =>
  async (dispatch) => {
    dispatch(analysisV2Slice.actions.fetchQuestion());
    try {
      const response = await apiClient.get(
        ENDPOINTS.ANALYSISV3.OVERVIEW +
          `/${packId}/${testId}/questionwise/${questionId}`
      );
      dispatch(analysisV2Slice.actions.fetchQuestionSuccess(response.data));
    } catch (error) {
      dispatch(analysisV2Slice.actions.fetchQuestionFailure(error));
    }
  };

export default analysisV2Slice.reducer;