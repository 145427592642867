import apiClient from "../../utils/apiClient";
import { createSlice } from "@reduxjs/toolkit";
import { objectToQueryParams } from "../../utils/objectToQuery";

const createInitialStateEntry = () => ({
  data: null,
  loading: false,
  error: null,
});

const initialState = {
  chapters: createInitialStateEntry(),
  packSubjects: createInitialStateEntry(),
  progressTrackVideo: '',
};

export const chapterTrackSlice = createSlice({
  name: "chapterTrack",
  initialState,
  reducers: {
    fetchPackSubjects: (state) => {
      state.packSubjects.loading = true;
    },
    fetchPackSubjectsSuccess: (state, action) => {
      state.packSubjects.data = action.payload.data?.subjects;
      state.progressTrackVideo = action.payload.data?.progressTrackVideo;
      state.packSubjects.loading = false;
      state.packSubjects.error = null;
    },
    fetchPackSubjectsFailure: (state, action) => {
      state.packSubjects.data = null;
      state.packSubjects.loading = false;
      state.packSubjects.error = action.payload;
    },
    fetchChapters: (state) => {
      state.chapters.loading = true;
    },
    fetchChaptersSuccess: (state, action) => {
      state.chapters.data = action.payload.data;
      state.chapters.loading = false;
      state.chapters.error = null;
    },
    fetchChaptersFailure: (state, action) => {
      state.chapters.data = null;
      state.chapters.loading = false;
      state.chapters.error = action.payload;
    },
  },
});

export const fetchPackSubjects = (packId) => async (dispatch) => {
  dispatch(chapterTrackSlice.actions.fetchPackSubjects());
  try {
    const response = await apiClient.get(
      `/api/v2/chapter-track/pack/${packId}/subjects`
    );
    dispatch(chapterTrackSlice.actions.fetchPackSubjectsSuccess(response.data));
  } catch (error) {
    dispatch(chapterTrackSlice.actions.fetchPackSubjectsFailure(error));
  }
};

export const fetchChapters = (params) => async (dispatch) => {
  dispatch(chapterTrackSlice.actions.fetchChapters());
  try {
    const queryParams = objectToQueryParams(params);
    const response = await apiClient.get(
      `/api/v2/chapter-track?${queryParams}`
    );
    dispatch(chapterTrackSlice.actions.fetchChaptersSuccess(response.data));
  } catch (error) {
    dispatch(chapterTrackSlice.actions.fetchChaptersFailure(error));
  }
};

export default chapterTrackSlice.reducer;
