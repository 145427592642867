import winston from "winston";
import { Loggly } from "winston-loggly-bulk";

const token = process.env.REACT_APP_LOGGLY_TOKEN;

const Logger = (error) => {
  winston.add(
    new Loggly({
      token,
      subdomain: "mathongo",
      tags: ["Winston-NodeJS"],
      json: true,
    })
  );
  winston.log("info", JSON.stringify(error));
};

export default Logger;
